import React, {useEffect, useRef, useState} from "react";
import {Input, InputColor, InputForwardRefProps} from "../index";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {ReactComponent as IconCheck} from "../../../assets/icon-check.svg";
import {CentralMapOverlay} from "../../Map/MapOverlay/Central";
import {useStoreActions, useStoreState} from "../../../store/hooks";
import { center, smoothScroll } from "../../../global/constants";
import {ReactComponent as LogoIcon} from "../../../assets/logo-icon-white.svg";
import {filterStations} from "../../../services/Input/PostalInput";
import {StationDto} from "../../../models/station.dto";
import {sortStations} from "../../../services/Map/MapOverlay/Central";
import axios from "axios";
import { AddressDto } from "../../../models/address.dto";
import { PlacesServiceProps } from "../../../feature/Steps/step2";

export interface HubType {
    id: number,
    title: string,
    address?: string,
    address_nr?: string,
    zipcode: string,
    city: string,
    country_code?: string,
    lat: number,
    lng: number,
    description?: string,
    openinghours?: [{
        day: number,
        time_open: number,
        time_close: number
    }],
    images?: [
        string
    ],
    type?: number
}

const PostalInput = ({ placesService }: PlacesServiceProps) => {
    const {t} = useTranslation("steps");

    const postalRef = useRef<HTMLInputElement>(null);
    const inputRef = useRef<InputForwardRefProps>(null);

    const hub = useStoreState(state => state.StepsModel.package.hub);
    const { setHub } = useStoreActions(actions => actions.StepsModel.package);

    const { stations, filteredStations } = useStoreState(state => state.LocationsModel);
    const { setStations, setFilteredStations } = useStoreActions(actions => actions.LocationsModel);

    const [inputValue, setInputValue] = useState("");
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);

    const [zipcodeLocation, setZipcodeLocation] = useState(center);

    const handleChange = (value: string) => {
        setInputValue(value);
    };

    const handleSelect = async (newHub: StationDto) => {
        setHub(newHub);

        setSuggestionsVisible(false);

        setInputValue("");
        if (inputRef.current) inputRef.current.setInputValue("");
    };

    const fetchZipcodeLocation = async () => {
        placesService?.findPlaceFromQuery({
            query: inputValue,
            fields: ["geometry"]
        },
        (results: google.maps.places.PlaceResult[] | null, status: google.maps.places.PlacesServiceStatus) => {
            if (status !== "OK") {
                setZipcodeLocation(center);
            } else {
                // @ts-ignore
                setZipcodeLocation({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
            }

            setSuggestionsVisible(true);
        });
    };

    useEffect(() => {
        if (/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(inputValue)) {
            fetchZipcodeLocation();
        } else {
            setSuggestionsVisible(false);
        }
        if (postalRef.current && inputValue !== "") postalRef.current.scrollIntoView({ behavior: "smooth" });
    }, [inputValue]);

    useEffect(() => {
        if (suggestionsVisible) filterStations(inputValue, stations, setFilteredStations);
    }, [suggestionsVisible]);

    const suggestionItem = (newHub: StationDto) => (
        <div className={"relative flex justify-between items-center border-diver border-b p-4 cursor-pointer"}
             onClick={() => handleSelect(newHub)}>
            <div className={"flex gap-2"}>
                {/* Icon */}
                <div className={"absolute flex justify-center items-center w-8 h-8 p-1.5"}>
                    <div className={"z-1"}>
                        <LogoIcon fill={"black"} />
                    </div>
                    <div className={"absolute flex w-full h-full bg-green opacity-20 rounded-full"} />
                </div>
                <div className={"flex flex-col ml-12"}>
                    <div className={"text-sm"}>{newHub.name}</div>
                    <div className={"text-sm text-gray-text"}>{newHub.postalCode}</div>
                </div>
            </div>
            <div className={"relative flex w-6 h-6 ml-2"}>
                <div className={`absolute right-0 flex justify-center items-center w-6 h-6 rounded-full border-1 ${
                    hub.id === newHub.id ? "border-green bg-green" : "border-grey"}`}>
                    {hub.id === newHub.id ? <IconCheck fill={"white"}/> : null}
                </div>
            </div>
        </div>
    );

    return(
        <div className={"flex w-24 h-17 scroll-mt-2 scroll-mt-2"} ref={postalRef}>
            <Input ref={inputRef}
                   placeholder="E.g 2273SK"
                   label={t("step_1.labels.postal_code")}
                   color={InputColor.DARK_GREY} showClear={true} uppercase={true}
                   passValueChange={handleChange}
                   onClick={() => (postalRef.current) ? smoothScroll(postalRef.current) : null}
            />
            {/* Mobile suggestions */}
            <div className={classNames({"hidden": !suggestionsVisible},
                "absolute fixed flex flex-col md:hidden bg-white w-screen h-fit top-full -left-6 pointer-events-auto shadow-lg z-5",
            )}>
                {sortStations(filteredStations).map((station) => (
                    <div key={station.id}>
                        {suggestionItem(station)}
                    </div>
                ))}
            </div>
            {/* Desktop suggestions */}
            {suggestionsVisible ? (
                <div className={"hidden md:block"}>
                    <CentralMapOverlay
                        hubs={stations}
                        center={zipcodeLocation}
                        onExit={() => {
                            setSuggestionsVisible(false);
                            setInputValue("");
                            if (inputRef.current) inputRef.current.setInputValue("");
                        }}
                        nationalZoom={JSON.stringify(center) === JSON.stringify(zipcodeLocation)}
                        suggestAll
                    />
                </div>
            ) : null}
        </div>
    )
}

export {PostalInput}
