import {OverlayPropTypes} from "../index";

const CentralOverlay = (props: OverlayPropTypes) => {
    return(
        <div className={"fixed container lg:w-3/4 h-1/2 mx-auto inset-x-0 top-1/2 transform -translate-y-1/2 z-50"}>
            {props.children}
        </div>
    );
}

export {CentralOverlay}
