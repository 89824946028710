import React, { useEffect, useRef } from "react";
import {PostalInput} from "../../components/Input/PostalInput";
import {ViewMap} from "../../components/Map/ViewMap";
import {useTranslation} from "react-i18next";
import {StepsHeader} from "../../components/Steps/StepsHeader";
import {Package} from "../../components/Package";
import {ReactComponent as CheckIcon} from "./../../assets/icon-check.svg";
import {colors} from "../../global/constants";
import {StepsFooter} from "../../components/Steps/StepsFooter";
import {Map} from "../../components/Map/map";
import {SummaryBlock} from "../../components/Steps/SummaryBlock";
import {ReactComponent as StepsIllustration} from "./../../assets/steps-illustration.svg";
import {useStoreState} from "../../store/hooks";
import {MobileSummaryBlock} from "../../components/Steps/SummaryBlock/Mobile";
import { PlacesServiceProps } from "./step2";

/* Send package step */
const Step1 = ({ placesService }: PlacesServiceProps) => {
    const {t} = useTranslation("steps");

    const packageRef = useRef<HTMLInputElement>(null);

    const { hub, packageType } = useStoreState(state => state.StepsModel.package);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className={"col-span-12 md:col-span-7 bg-white"}>
                {/* Header */}
                <StepsHeader index={0}>
                    <h1 className="hidden md:block mb-2">{t("step_1.header.title")}</h1>
                    <p className="md:text-md mb-5">{t("step_1.header.text")}</p>
                    <div className="relative flex justify-between md:justify-start">
                        <PostalInput placesService={placesService} />
                        <div className={"flex justify-end md:hidden"}>
                            <ViewMap />
                        </div>
                        {hub.id > -1 ? (
                            <div className={"hidden md:flex gap-3 pl-8 pt-6"}>
                                {/* Icon */}
                                <div className={"flex items-center"}>
                                    <CheckIcon fill={colors.green} />
                                </div>
                                <div className={"flex flex-col"}>
                                    <div className={"flex items-center"}>
                                        <span className={"text-sm"}>{hub.name}</span>
                                    </div>
                                    <div className={"flex items-center"}>
                                        <span className={"text-sm text-gray-text"}>{hub.postalCode}</span>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {hub.id > -1 ? (
                        <div className={"flex gap-3 pt-6 md:hidden"}>
                            {/* Icon */}
                            <div className={"flex items-center"}>
                                <CheckIcon fill={colors.green} />
                            </div>
                            <div className={"flex flex-col"}>
                                <div className={"flex items-center"}>
                                    <span className={"text-sm"}>{hub.name}</span>
                                </div>
                                <div className={"flex items-center"}>
                                    <span className={"text-sm text-gray-text"}>{hub.postalCode}</span>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {hub.id > -1 && (
                        <div className={"hidden relative md:block h-52 mt-5"}>
                            <Map overlay={true} selectedHub={hub} markers={[hub]} />
                        </div>
                    )}
                </StepsHeader>

                {/* Package(s) */}
                <div className={"bg-background md:p-10 md:mb-12 lg:px-20"} ref={packageRef}>
                    <Package id={0} />
                </div>

                {/* Summary block */}
                {packageType && packageType.id > -1 ? (
                    <MobileSummaryBlock />
                ) : null}
            </div>

            {/* Desktop right side */}
            <div className={"hidden col-span-4 col-start-9 md:flex flex-col justify-between h-full bg-white"}>
                {/* Summary block */}
                {packageType && packageType.id > -1 ? (
                    <SummaryBlock />
                ) : null}

                {/* Image SVG */}
                <div className={"flex justify-center items-end w-full h-full mb-12"}>
                    <StepsIllustration />
                </div>
            </div>

            <div className={"hidden md:flex mb-16"}>
                {hub.id > -1 && packageType.id > -1 ? (
                    <StepsFooter />
                ) : null}
            </div>
        </>
    )
}

export {Step1}
