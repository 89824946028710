import {VisualPackageType} from "../../../components/Package";
import {TFunction} from "i18next";

export type Entries<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];

export const getPackageTypes = (t: TFunction) => {
    const packageTypes: VisualPackageType[] = [];
    const types = require("../../../assets/data/packagetypes.json");
    for (const [key, value] of Object.entries(types) as Entries<typeof types>) {
        packageTypes.push({
            id: value.id,
            name: value.name,
            symbol: value.symbol,
            weight: t("type.until_" + value.weight),
            description: t("type.dimension_" + value.description),
            type: value.type
        })
    }

    return packageTypes;
}

export const getDestinations = (t: TFunction) => {
    const destinations = [];
    const countries = require("../../../assets/data/countries.json");
    for (const [key, value] of Object.entries(countries) as Entries<typeof countries>) {
        // Exclude The Netherlands as a destination
        if (key !== "nl") destinations.push({
            value: key,
            symbol: key,
            text: t(key.toString())
        });
    }

    return destinations;
}

export const generateVisualId = (id: number) => {
    const visualId = id + 1;
    if (visualId < 10) return "0" + visualId.toString();
    return visualId.toString();
}
