import React from "react";
import {getApiEndpoint} from "../services/api";

// Center coordinates of The Netherlands
export const center = {
    lat: 52.132633,
    lng: 5.291266
}

export const rotterdam = {
    lat: 51.924419,
    lng: 4.477733
}

export const urls = {
    api: {
        createPackage: getApiEndpoint() + "International/createorder"
    },
    success: "/success"
}

export const googleMapsLibraries = ["places"];

export const colors = {
    'primary': '#21343B',
    'text': '#1B3038',
    'grey':'#BBC1C3',
    'gray': '#8D989C',
    'dark_grey': '#F3F4F5',
    'light_grey': '#E8EAEB',
    'green': '#00B483',
    'light_green': '#E6F8F3',
    'red': '#DC3644',
    'gray_text': '#758085',
    'background': '#F8FAFA',
    'white': '#fff',
    'transparent': 'transparent',
    'diver': '#EBF1F1'
}

export const smoothScroll = (target:HTMLElement) => {
    target.scrollIntoView({ block: "start", behavior: "smooth" });
}
