import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonTypeEnum } from "../../components/Button";
import { StepsHeader } from "../../components/Steps/StepsHeader";
import { InputStep } from "../../components/Steps/InputStep";
import { StepsFooter } from "../../components/Steps/StepsFooter";
import { SummaryBlock } from "../../components/Steps/SummaryBlock";
import { ReactComponent as StepsIllustration } from "../../assets/steps-illustration.svg";
import { useStoreActions, useStoreState } from "../../store/hooks";
import { validateAddress } from "../../services/Steps/InputStep";
import { Overlay } from "../../components/Overlay";
import { Warning } from "../../components/Steps/Warning";
import { PlacesServiceProps } from "./step2";

/* Receiver step */
const Step3 = ({ placesService }: PlacesServiceProps) => {
    const { t } = useTranslation("steps");
    const tc = useTranslation("countries").t;

    const { setStep } = useStoreActions(actions => actions.StepsModel);

    const { validated, name, postcode, number, street, city, country, prefix, telephone, email } = useStoreState(state => state.StepsModel.receiver);
    const { touched, nameTouched, postcodeTouched, numberTouched, streetTouched, cityTouched, countryTouched, telephoneTouched, emailTouched } = useStoreState(state => state.StepsModel.receiver);
    const { setValidated, setName, setPostcode, setNumber, setStreet, setCity, setCountry, setPrefix, setTelephone, setEmail } = useStoreActions(actions => actions.StepsModel.receiver);
    const { setTouched, setNameTouched, setPostcodeTouched, setNumberTouched, setStreetTouched, setCityTouched, setCountryTouched, setTelephoneTouched, setEmailTouched } = useStoreActions(actions => actions.StepsModel.receiver);

    const [showWarning, setShowWarning] = useState(false);

    const validateAddressButton = (
        <div className={"relative w-full md:w-fit h-fit"}>
            <Button buttonType={ButtonTypeEnum.PRIMARY} text={t("step_2.buttons.continue")}
                    onClick={() => setStep(3)}
                    /*onAsyncClick={async () => {
                        await validateAddress(placesService, city, postcode, number, street, country, tc)
                            .then((r) => {
                                // @ts-ignore
                                if (r.warning) {
                                    setShowWarning(true);
                                } else {
                                    setStep(3);
                                }
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    }}*/
            />
        </div>
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className={"col-span-12 md:col-span-7 bg-white md:mb-12"}>
                {/* Header */}
                <StepsHeader index={2} text={t("step_3.header.text")} title={t("step_3.header.title")} />
                {/* Inputs */}
                <InputStep
                    service={placesService}
                    touched={touched} setTouched={setTouched}
                    name={name} setName={setName}
                    nameTouched={nameTouched} setNameTouched={setNameTouched}
                    postcode={postcode} setPostcode={setPostcode}
                    postcodeTouched={postcodeTouched} setPostcodeTouched={setPostcodeTouched}
                    number={number} setNumber={setNumber}
                    numberTouched={numberTouched} setNumberTouched={setNumberTouched}
                    street={street} setStreet={setStreet}
                    streetTouched={streetTouched} setStreetTouched={setStreetTouched}
                    city={city} setCity={setCity}
                    cityTouched={cityTouched} setCityTouched={setCityTouched}
                    country={country} setCountry={setCountry}
                    countryTouched={countryTouched} setCountryTouched={setCountryTouched}
                    prefix={prefix} setPrefix={setPrefix}
                    telephone={telephone} setTelephone={setTelephone}
                    telephoneTouched={telephoneTouched} setTelephoneTouched={setTelephoneTouched}
                    email={email} setEmail={setEmail}
                    emailTouched={emailTouched} setEmailTouched={setEmailTouched}
                    setValidated={setValidated}
                />
                {/* Button */}
                <div className={"flex md:hidden bg-white p-6 py-10"}>
                    <Button buttonType={ButtonTypeEnum.PRIMARY} text="Continue shipping"
                            disabled={!validated} onClick={() => setStep(3)} />
                </div>
            </div>

            {/* Desktop right side */}
            <div className={"hidden md:flex flex-col justify-between h-full col-span-4 col-start-9 bg-white"}>
                {/* Summary block */}
                <SummaryBlock />

                {/* Image SVG */}
                <div className={"flex justify-center items-end w-full h-full mb-12"}>
                    <StepsIllustration />
                </div>
            </div>

            <div className={"hidden md:block mb-16"}>
                {validated ? (
                    <StepsFooter continueButton={validateAddressButton} />
                ) : null}
            </div>

            {showWarning ? (
                <Overlay blur>
                    <Warning
                        title={t("step_2.warnings.address.unable")}
                        message={t("step_2.warnings.address.check")}
                        okFunction={() => setStep(3)}
                        cancelFunction={() => setShowWarning(false)}
                    />
                </Overlay>
            ) : null}
        </>
    )
}

export {Step3}
