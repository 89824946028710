import axios from "axios";
import {PackageDto} from "../../models/package.dto";
import {Store} from "easy-peasy";
import {ModelsInterface} from "../../store";
import {urls} from "../../global/constants";

interface CreateOrderRes {
    redirectUrl: string;
    status: string;
    trx: string;
}

export const getApiEndpoint = () => {
    return "https://api.acc.izipack.nl/api/"
}

export const getStations = async () => {
    const data = await axios.get(getApiEndpoint() + "User/station")
        .then(res => res.data);
}

export async function sendPackage(store: Store<ModelsInterface, any>) {
    const stepStates = store.getState().StepsModel;

    const payload: PackageDto = {
        hubId: stepStates.package.hub.id,
        size: stepStates.package.packageType.name,
        amount: parseFloat(stepStates.package.price.replace(",", ".")),
        reference: "string",
        sender: {
            name: stepStates.sender.name,
            phoneNumber: stepStates.sender.prefix.value + " " + stepStates.sender.telephone,
            email: stepStates.sender.email,
            street: stepStates.sender.street,
            zipCode: stepStates.sender.postcode,
            city: stepStates.sender.city,
            country: stepStates.sender.country.toUpperCase()
        },
        receiver: {
            name: stepStates.receiver.name,
            phoneNumber: stepStates.receiver.prefix.value + " " + stepStates.receiver.telephone,
            email: stepStates.receiver.email,
            street: stepStates.receiver.street,
            zipCode: stepStates.receiver.postcode,
            city: stepStates.receiver.city,
            country: stepStates.receiver.country.toUpperCase()
        }
    };

    const res = await axios.post<PackageDto, { data: CreateOrderRes }>(urls.api.createPackage, payload)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err.message));

    return Promise.resolve(res);
}
