import IzipackLogo from "../../../assets/izipack_logo.png";
import { ReactComponent as PaymentIllustration } from "../../../assets/payment-illustration.svg";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "../../../global/hooks";
import { useEffect } from "react";

const StepError = () => {
    const {t} = useTranslation("steps");

    // Redirect to homepage after delay
    useEffect(() => {
        setTimeout(() => {
           window.location.assign("https://izipack.nl");
        }, 10000);
    }, []);

    return (
        <div className={"col-span-12 md:container w-full h-full bg-white p-6 md:px-0"}>
            <div className={"flex justify-center mt-3 mb-6 md:mb-12"}>
                <img src={IzipackLogo} alt={"Izipack logo"} />
            </div>

            <div className={"flex flex-col justify-center w-fit mx-auto bg-red bg-opacity-[60%] rounded gap-4 p-8"}>
                <div className={"flex md:flex-col justify-center text-center font-semibold text-xl md:text-2xl"}>
                    {t("error.something_wrong")}
                </div>
                <div className={"flex justify-center text-center"}>
                    {t("error.retry_or")}&nbsp;
                    <a href={"mailto:logistiek@izipack.nl"} className={"underline font-semibold"}>
                        {t("error.contact_us")}
                    </a>.
                </div>
            </div>

            {/*<div className={"flex justify-center items-center h-96"}>
                <PaymentIllustration />
            </div>*/}

            <div className={"flex justify-center mt-8"}>
                <div className={"w-48 text-center text-gray-text"}>
                    {t("step_5.redirection")}
                </div>
            </div>
        </div>
    )
}

export {StepError};
