import {createStore} from 'easy-peasy';
import StepsModel from "./models/steps";
import LocationsModel from "./models/locations";

export interface ModelsInterface {
    StepsModel: StepsModel,
    LocationsModel: LocationsModel
}

const models = {
    StepsModel,
    LocationsModel
}

const store = createStore<ModelsInterface>(models);

export default store;
