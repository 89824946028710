import React, {useEffect} from "react";
import {useStoreActions, useStoreState} from "../../../store/hooks";
import {getPrice} from "../../../services/Steps/SummaryBlock";
import {useTranslation} from "react-i18next";

const SummaryBlock = () => {
    const {t} = useTranslation("components");

    const { packageType, destination, price} = useStoreState(state => state.StepsModel.package);
    const { setPrice } = useStoreActions(actions => actions.StepsModel.package);

    useEffect(() => {
        if (packageType.name !== "") setPrice(getPrice(destination.value, packageType.type));
    }, [packageType]);

    return(
        <div className={"flex flex-col h-fit bg-white p-6 md:p-4 lg:p-6 mt-20 lg:ml-5 lg:mb-4 shadow-top"}>
            {/* Pricing */}
            <div className={"flex flex-col gap-2"}>
                <div className={""}>
                    <label className={"text-sm font-bold"}>{t("summaryblock.your_price")}</label>
                </div>
                <div className={"flex flex-col border-b-1 border-light-grey pl-5 gap-1 pb-2"}>
                    <label className={"text-sm font-semibold"}>{t("summaryblock.package")} - {destination.text}</label>
                    <div className={"flex justify-between"}>
                        <label className={"text-sm mr-0.5"}>{t("summaryblock.package")} {packageType.name}</label>
                        <label className={"text-sm whitespace-nowrap"}>€ {price}</label>
                    </div>
                </div>
                <div className={"flex justify-between"}>
                    <label className={"text-sm"}>
                        <span className={"font-bold text-base text-green"}>{t("summaryblock.total")}</span> {t("summaryblock.tax")}
                    </label>
                    <label className={"text-lg font-bold text-right whitespace-nowrap w-1/4 lg:w-auto"}>€ {price}</label>
                </div>
            </div>
        </div>
    );
}

export {SummaryBlock}
