import {MenuItems} from "./MenuItems";
import IzipackLogo from "../../../assets/izipack_logo.svg";
import {useTranslation} from "react-i18next";

export interface MenuList {
    text:string,
    link:string,
    subLinkItems?: MenuList[]
}

const Navbar = () => {
    const {t} = useTranslation("components");

    return (
        <header>
            <nav className="flex justify-between bg-white pt-5">
                <div className={"pl-6 md:pl-0"}>
                    <img src={IzipackLogo} alt="Izipack logo"/>
                </div>
                <ul className="hidden md:block">
                    <MenuItems items={[
                        {text: t("navbar.companies"), link: "https://izipack.nl/bedrijven/"}
                    ]}/>
                </ul>
            </nav>
        </header>
    )
}

export {Navbar}
