import {ReactComponent as SearchIcon} from "../../../assets/search.svg";
import {useTranslation} from "react-i18next";
import { center, colors } from "../../../global/constants";
import React, {useState} from "react";
import {Overlay} from "../../Overlay";
import {MapOverlay} from "../MapOverlay";
import {useStoreState} from "../../../store/hooks";

const ViewMap = () => {
    const {t} = useTranslation("steps");

    const [showOverlay, setShowOverlay] = useState(false);

    const hub = useStoreState(state => state.StepsModel.package.hub);
    const { stations } = useStoreState(state => state.LocationsModel);

    return (
        <div className="flex flex-col justify-end">
            <div className={"flex items-center w-full py-2 my-1 cursor-pointer"} onClick={() => setShowOverlay(true)}>
                <SearchIcon fill={colors.primary} />
                <p className="mt-0.5 ml-2">{t("step_1.labels.view_map")}</p>
            </div>
            {showOverlay ? (
                <Overlay>
                    <MapOverlay
                        onExit={() => setShowOverlay(false)}
                        hubs={stations}
                        center={hub.id > -1 ? { lat: hub.latitude, lng: hub.longitude } : center}
                        zoom={hub.id > -1 ? 12 : 8}
                    />
                </Overlay>
            ) : null}
        </div>
    );
}

export {ViewMap}
