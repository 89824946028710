import React, {useRef} from "react";
import {PackageType} from "./PackageType";
import {Select, SelectObject} from "../Select";
import {useTranslation} from "react-i18next";
import {useStoreActions, useStoreState} from "../../store/hooks";
import {generateVisualId, getDestinations, getPackageTypes} from "../../services/Steps/Package";
import { getPrice } from "../../services/Steps/SummaryBlock";

export interface PackageType {
    id: number,
    name: string,
    symbol: string,
    type: string
}

export interface VisualPackageType extends PackageType {
    weight: string,
    description: string
}

interface PackageProps {
    id: number,
    //defaultDestination: SelectObject,
    //passPackageData: (type: PackageType, destination: SelectObject) => void
}

const Package = ({ id }: PackageProps) => {
    const {t} = useTranslation("packages");
    const tc = useTranslation("countries").t;

    const packageType = useStoreState(state => state.StepsModel.package.packageType);
    const { setPackageType } = useStoreActions(actions => actions.StepsModel.package);

    const { setDestination, setPrice } = useStoreActions(actions => actions.StepsModel.package);

    const headerRef = useRef<HTMLInputElement>(null);

    const scrollToHeader = () => {
        if (headerRef.current) headerRef.current.scrollIntoView();
    };

    const handleChange = (value: SelectObject) => {
        setDestination(value);
        if (packageType.type !== "") setPrice(getPrice(value.value, packageType.type));
    };

    const handleTypeClick = (type: PackageType, e: React.MouseEvent<HTMLElement>) => {
        setPackageType({
            id: type.id, name: type.name, symbol: type.symbol,
            type: type.type
        });
        //(e.target as HTMLElement).scrollIntoView({ block: "start", behavior: "smooth" });
    };

    const packageTypes = getPackageTypes(t);
    const destinations = getDestinations(tc);

    return (
        <>
            <div className="flex justify-between px-6 bg-white md:hidden">
                <h3 ref={headerRef}>{t("package")}{/* generateVisualId(id)*/}</h3>
                {/*<div className={"flex items-center px-2"} onClick={() => console.log("Remove package " + id)}>
                    <span className={"text-xs text-red"}>Remove</span>
                </div>*/}
            </div>
            {packageTypes.map((type, index) => (
                <div key={index} onClick={(e) => handleTypeClick(type, e)}>
                    <PackageType id={type.id}
                                 name={type.name}
                                 symbol={type.symbol}
                                 selected={packageType.id}
                                 weight={type.weight}
                                 description={type.description} />
                </div>
            ))}

            <div className={"px-6 md:px-0 pt-5 md:pt-2 pb-7 md:pb-0"}>
                <Select label={t("destination.destination")}
                        options={destinations}
                        handleChange={handleChange}
                />
            </div>
        </>
    );
}

export {Package}
