import { useEffect, useState, Fragment, useImperativeHandle, forwardRef, MouseEventHandler } from "react";
import {Overlay} from "../Overlay";
import {ReactComponent as CrossIcon} from "./../../assets/cross.svg";
import {ReactComponent as SearchIcon} from "./../../assets/search.svg";
import {ReactComponent as CheckIcon} from "./../../assets/icon-check.svg";
import {ReactComponent as ArrowDown} from "./../../assets/chevron-down-solid.svg";
import classNames from "classnames";
import {colors} from "../../global/constants";
import { Combobox, Transition } from '@headlessui/react'
import {useTranslation} from "react-i18next";
import Flags from "country-flag-icons/react/3x2";
import { useStoreState } from "../../store/hooks";


export interface SelectObject {
    value: string,
    symbol: string,
    text: string
}

export enum SelectDirection {
    UP, DOWN
}

interface SelectProps {
    label?: string,
    options: SelectObject[],
    defaultValue?: SelectObject,
    handleChange: ((value: SelectObject) => void) | undefined,
    onClick?: MouseEventHandler<HTMLDivElement>,
    direction?: SelectDirection,
    tight?: boolean
}

export interface SelectForwardRefProps {
    setSelectValue: (value: SelectObject) => void,
}

const Select = forwardRef<SelectForwardRefProps, SelectProps>(({ label, options, defaultValue = options[0], handleChange, onClick , direction = SelectDirection.UP, tight = false}, ref) => {
    const {t} = useTranslation("components");

    const { step } = useStoreState(state => state.StepsModel);

    const [selected, setSelected] = useState(defaultValue);
    // @ts-ignore
    const SelectedFlag = Flags[selected.symbol.toUpperCase()];

    useImperativeHandle(ref, () => ({
        setSelectValue(value: SelectObject) {
            setSelected(value);
        }
    }));

    const [searchValue, setSearchValue] = useState("");

    const [currentOptions, setCurrentOptions] = useState(options);

    const [showOverlay, setShowOverlay] = useState(false);

    const filterOptions = (value: string) => {
        const filteredOptions = options.filter((option) => {
            return option.text.toLowerCase().includes(value);
        });
        setCurrentOptions(filteredOptions);
    };

    useEffect(() => {
        filterOptions(searchValue.toLowerCase());
    }, [searchValue]);

    // Pass value to parent component
    useEffect(() => {
        if (handleChange) handleChange(selected);
    }, [selected])

    // Reset search upon exit
    useEffect(() => {
        if (!showOverlay) setSearchValue("");
    }, [showOverlay]);

    const optionSelection = (
        <div className={"flex flex-col w-full h-screen"}>
            <div className={"flex flex-col p-7 gap-5"}>
                <div className={"flex justify-between items-center"}>
                    <div className={"flex items-center"}>
                        <span className={"text-lg font-bold"}>Select destination</span>
                    </div>
                    <div className={"flex justify-center items-center w-6 h-6 bg-text rounded-full cursor-pointer p-1.5"}
                         onClick={() => setShowOverlay(false)}>
                        <CrossIcon fill={"white"} />
                    </div>
                </div>

                <div className={"relative flex w-full h-12 bg-white"}>
                    <input className={classNames("peer w-full h-full bg-white text-sm p-2 rounded border border-transparent",
                        {
                            "pl-10": searchValue === "",
                        })}
                           type="text" value={searchValue} placeholder={"Search country"} onChange={(e) => setSearchValue(e.target.value.toLowerCase())} />
                    <div className={"absolute flex justify-center items-center w-6 h-6 top-1/2 left-2 transform -translate-y-1/2 pointer-events-none"}>
                        {searchValue === "" ? (
                            <SearchIcon fill={colors.gray_text} />
                        ) : null}
                    </div>
                    <div className={classNames("absolute flex justify-center items-center w-6 h-6 top-1/2 right-2 transform -translate-y-1/2",
                        {
                            "pointer-events-none": selected.value === "",
                        },
                        {
                            "pointer-events-auto cursor-pointer": selected.value !== "",
                        })}
                         onClick={() => setSearchValue("")}>
                        {searchValue !== "" ? (
                            <CrossIcon fill={colors.gray} />
                        ) : null}
                    </div>
                </div>
            </div>

            <div className={"overflow-y-scroll"}>
                {currentOptions.map((option, index) => {
                    // @ts-ignore
                    const Flag = Flags[option.symbol.toUpperCase()];
                    return (
                        <div className={classNames("relative flex items-center w-full h-14 bg-white p-2 cursor-pointer",
                            {
                                "border-b-diver border-b": index !== currentOptions.length - 1,
                            })}
                             onClick={() => {
                                 setSelected(option);
                                 setShowOverlay(false);
                             }}
                             key={index}>
                            {/* Flag */}
                            <div className={"flex w-10 h-full ml-2 mr-5"}>
                                <Flag className={"w-full h-full"} />
                            </div>

                            {/* Text */}
                            <div className={"w-full"}>
                                <span>{option.text}</span>
                            </div>
                            {/* Selected symbol */}
                            {option.value === selected.value ? (
                                <div className={"absolute right-5"}>
                                    <CheckIcon fill={colors.green} />
                                </div>
                            ) : null}
                        </div>
                    );
                })}
            </div>

        </div>
    );

    return (
        <div className={"w-full h-full"} onClick={onClick}>
            {label ? (
                <label className="block text-sm mb-1" htmlFor="">{label}</label>
            ) : null}

            {/* Custom mobile select */}
            <div className={"relative flex md:hidden w-full h-12 bg-white p-3 cursor-pointer"} onClick={() => setShowOverlay(true)}>
                <div className={"flex w-8 h-full ml-2 mr-3"}>
                    <SelectedFlag className={"w-full h-full"} />
                </div>
                <div className={"flex items-center w-full"}>
                    <span className={"text-gray-text text-sm"}>
                        {/*{options.filter((option) => {return option.value === selected.value})[0].text}*/}
                        {selected.text}
                    </span>
                </div>
                <div className={"absolute w-3 right-2 sm:right-5 top-1/2 transform -translate-y-1/2 text-gray-text"}>
                    <ArrowDown fill={colors.gray_text} />
                </div>
            </div>

            {showOverlay ? (
                <Overlay>
                    {optionSelection}
                </Overlay>
            ) : null}

            {/* HeadlessUI desktop Combobox */}
            <div className="hidden md:block">
                <Combobox value={selected} onChange={setSelected}>
                    <div className="relative text-primary">
                        <div className="flex w-full text-sm bg-white rounded border border-transparent">
                            <div className={"absolute flex w-9 h-full left-3 pointer-events-none"}>
                                <SelectedFlag className={"w-full h-full"} />
                            </div>
                            <Combobox.Input
                                className="w-full h-12 border-none pl-16 pr-10 text-sm leading-5 outline-black"
                                displayValue={(selected: SelectObject) => selected?.text}
                                onChange={(event) => setSearchValue(event.target.value)}
                            />
                            <Combobox.Button className="absolute flex justify-center items-center inset-y-0 right-0 pr-4">
                                <div className={"absolute w-3 right-5 text-gray-text"}>
                                    <ArrowDown fill={colors.gray_text} />
                                </div>
                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setSearchValue("")}
                        >
                            <Combobox.Options className={classNames(
                                "absolute w-full overflow-auto transform rounded-md bg-white text-base max-h-[calc(6*3.5rem)] text-sm",
                                {
                                    "-translate-y-full top-0 shadow-top mb-1": direction === SelectDirection.UP,
                                },
                                {
                                    "shadow-bottom z-1": direction === SelectDirection.DOWN,
                                }
                            )}>
                                {currentOptions.length === 0 && searchValue !== "" ? (
                                    <div className="relative flex items-center cursor-default select-none text-sm w-full h-14 p-2 text-gray-700">
                                        {t("select.no_results")}
                                    </div>
                                ) : (
                                    currentOptions.map((option, index) => {
                                        // @ts-ignore
                                        const Flag = Flags[option.symbol.toUpperCase()];
                                        return (
                                            <Combobox.Option
                                                key={option.value}
                                                className={classNames("relative flex items-center w-full h-14 bg-white p-2 cursor-pointer", {
                                                    "border-b-diver border-b": index !== currentOptions.length - 1,
                                                })}
                                                value={option}
                                            >
                                                <div className={"flex w-8 h-full ml-2 mr-3"}>
                                                    <Flag className={"w-full h-full"} />
                                                </div>
                                                <span className={"text-sm text-primary"}>
                                                    {option.text}
                                                </span>
                                                {selected.value === option.value ? (
                                                    <div className={classNames("absolute", { "right-2": tight }, { "right-3": !tight })}>
                                                        <CheckIcon fill={colors.green} />
                                                    </div>
                                                ) : null}
                                            </Combobox.Option>
                                        )
                                    })
                                )}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </Combobox>
            </div>
        </div>
    );
})

export {Select}
