import React, {useEffect, useState, forwardRef, useImperativeHandle, useRef} from "react";
import classNames from "classnames";
import {colors} from "../../global/constants";
import {ReactComponent as CrossIcon} from "./../../assets/cross.svg";
import {ReactComponent as CheckIcon} from "../../assets/icon-check.svg";

export enum InputColor {
    WHITE = "white", DARK_GREY = "dark-grey"
}

export enum InputType {
    TEXT = "text", NUMBER = "number"
}

interface InputProps {
    type?: InputType,
    label?: string,
    placeholder: string,
    color: InputColor,
    disabled?: boolean,
    validated?: boolean,
    error?: boolean,
    errorMessage?: string,
    showClear?: boolean,
    uppercase?: boolean,
    setInputValue?: string,
    passValueChange?: (value: any) => void,
    onClick?: () => void,
    onChange?: (e: React.ChangeEvent) => void,
    onBlur?: () => void,
    onFocus?: () => void
}

export interface InputForwardRefProps {
    setInputValue: (value: string) => void,
}

const Input = forwardRef<InputForwardRefProps, InputProps>(({type = InputType.TEXT, placeholder, label, color, disabled = false, validated = false, error = false, errorMessage = "", showClear = false, uppercase = false, setInputValue, passValueChange, onClick, onChange, onBlur, onFocus}, ref) => {
    const [value, setValue] = useState("");

    useImperativeHandle(ref, () => ({
        setInputValue(value: string) {
            setValue(value);
        }
    }));

    useEffect(() => {
        if (passValueChange) passValueChange(value);
    }, [value]);

    const inputRender = (placeholder: string) => (
        <div className={"relative w-full h-full"}>
            <input className={classNames("peer w-full h-full text-sm p-2 border border-transparent rounded-sm focus:ring-black focus:ring-transparent outline-2 outline-primary",
                {
                    "bg-dark-grey": color === "dark-grey",
                },
                {
                    "bg-white": color === "white",
                },
                {
                    "scroll-mt-8": label,
                }
            )}
                   type={type} value={value} placeholder={placeholder}
                   onClick={onClick} onBlur={onBlur} onFocus={onFocus}
                   onChange={(e) => {
                       if (uppercase) {
                           setValue(e.target.value.toUpperCase());
                       } else {
                           setValue(e.target.value);
                       }

                       if (onChange) onChange(e);
                   }}
                   disabled={disabled}
            />
            {showClear && (
                <div className={classNames("absolute flex justify-center items-center w-6 h-6 top-1/2 right-2 transform -translate-y-1/2",
                    {
                        "pointer-events-none": value === "",
                    },
                    {
                        "pointer-events-auto cursor-pointer": value !== "",
                    })}
                     onClick={() => setValue("")}>
                    {value !== "" ? (
                        <CrossIcon fill={colors.gray} />
                    ) : null}
                </div>
            )}
            {disabled && (
                <div className={"absolute top-0 w-full h-full bg-gray-text bg-opacity-25 rounded cursor-not-allowed"} />
            )}
            {error && !disabled && (
                <>
                    {/* Red overlay */}
                    <div className={"absolute w-full h-full top-0 bg-red bg-opacity-50 border-1 border-red rounded pointer-events-none outline-0 outline-transparent"} />
                    {/* Error label */}
                    <div className={"absolute flex w-full h-fit gap-1 mt-0.5"} >
                        <div className={"flex items-center w-4 h-4 bg-red bg-opacity-25 rounded-full p-[0.3rem] mt-0.5"}>
                            <CrossIcon fill={colors.red} />
                        </div>
                        <div className={"flex items-center text-red text-xs mt-0.5"}>
                            {errorMessage === "" ? "Error message" : errorMessage}
                        </div>
                    </div>
                </>
            )}
            {validated && !disabled && (
                <div className={"absolute bottom-1/2 right-5 transform translate-y-1/2"}>
                    <CheckIcon fill={colors.green} />
                </div>
            )}
        </div>
    );

    return (
        <div className={"flex flex-col w-full h-full"}>
            {label ? (
                <label className="block text-sm mb-1.5" htmlFor="">{label}</label>
            ) : null}
            {inputRender(placeholder)}
        </div>
    );
})

export {Input}
