type Color = {
    [key: string]: {
        [key: string]: string
    }
}

export const colors: Color = {
    icon : {
        light: "#BBC1C3",
        dark: "#21343B"
    }
}
