import React from "react";
import {StepsProgress} from "../StepsProgress";
import {useTranslation} from "react-i18next";

interface StepsHeaderProps {
    index: number,
    text?: string,
    title?: string,
    children?: React.ReactNode
}

const StepsHeader = ({ index, text, title, children }: StepsHeaderProps) => {
    const {t} = useTranslation("components");

    return (
        <div className="relative px-6 mb-5 md:px-0 bg-white">
            <p className="hidden md:block pt-7">
                <a href="https://www.izipack.nl/" target="_blank">Home</a> {">>"} {t("navbar.send")}
            </p>
            <StepsProgress stepIndex={index} />
            {title && (
                <h1 className="hidden md:block mb-2">{title}</h1>
            )}
            {text && (
                <p className="md:text-md mb-4">{text}</p>
            )}
            {title && (
                <div className="md:hidden pt-4 pb-1">
                    <h3>{title}</h3>
                </div>
            )}
            {children}
        </div>
    )
}

export {StepsHeader}
