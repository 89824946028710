import { ReactComponent as PackageIcon } from "../../../assets/icon-package.svg";
import { ReactComponent as IconPerson } from "../../../assets/icon-person.svg";
import { ReactComponent as IconLocation } from "../../../assets/icon-location.svg";
import { ReactComponent as IconEnd } from "../../../assets/icon-end.svg";
import { ReactComponent as IconCheck } from "../../../assets/icon-check.svg";
import { colors } from "../constants";
import classNames from "classnames";
import {useStoreActions} from "../../../store/hooks";

interface StepProps {
    stepIndex: number
}

interface StepItem {
    icon: string
}

const stepItems: StepItem[] = [
    {
        icon: "PackageIcon"
    },
    {
        icon: "IconPerson"
    },
    {
        icon: "IconLocation"
    },
    {
        icon: "IconEnd"
    }
]

const renderIcon = (icon: string, color: string) => {
    switch(icon) {
        case "PackageIcon":
            return <PackageIcon fill={color} />
        case "IconPerson":
            return <IconPerson stroke={color} />
        case "IconLocation":
            return <IconLocation fill={color} />
        case "IconEnd":
            return <IconEnd stroke={color} />
    }
}

const StepsProgress = ({ stepIndex }: StepProps) => {
    const setStep = useStoreActions((actions) => actions.StepsModel.setStep);

    return (
        <div className="flex justify-center md:justify-start pt-6">
            <div className="flex w-fit mb-5">
                {stepItems.map((step, index) => (
                    <div className="flex" key={index}>
                        <div className={classNames("flex items-center rounded-full border-1",
                            {
                                "bg-green border-green cursor-pointer": index < stepIndex,
                            },
                            {
                                "bg-grey border-grey cursor-pointer": index === stepIndex,
                            },
                            {
                                "border-grey cursor-not-allowed": index > stepIndex
                            }
                        )} onClick={() => {
                            if (index < stepIndex) {
                                setStep(index);
                            }
                        }}>
                            <div className={"flex justify-center items-center w-11 h-11 p-2.5"}>
                                {
                                    index < stepIndex ? (
                                        <IconCheck fill={"white"} />
                                    ) : (
                                        renderIcon(step.icon, index === stepIndex ? colors.icon.dark : colors.icon.light)
                                    )
                                }
                            </div>
                        </div>

                        {stepItems.length - 1 !== index ? (
                            <div className="flex w-5 align-center align-middle items-center content-center">
                                <div className="w-full h-0.5 bg-light-grey" />
                            </div>
                        ) : null}
                    </div>
                ))}
            </div>
        </div>
    )
}

export {StepsProgress}
