import {MenuList} from "../index";
import {Button, ButtonTypeEnum} from "../../../Button";
import {useTranslation} from "react-i18next";

interface MenuItemProps {
    items: MenuList[]
}

const MenuItems = ({items}: MenuItemProps) => {
    const {t} = useTranslation("components");

    return (
        <ul className="flex items-center gap-9">
            {items.map((item, index) =>
                <li key={index}>
                    <a href={item.link}>{item.text}</a>
                </li>
            )}
            <Button buttonType={ButtonTypeEnum.DEFAULT} text={t("navbar.send")}/>
        </ul>
    )
}

export {MenuItems}
