import IzipackLogo from "./../../assets/izipack_logo.png";
import {ReactComponent as PaymentIllustration} from "./../../assets/payment-illustration.svg";
import {useWindowDimensions} from "../../global/hooks";
import {useTranslation} from "react-i18next";
import { useEffect } from "react";

/* Succes step */
const Step5 = () => {
    const {t} = useTranslation("steps");

    const windowWidth = useWindowDimensions().width;

    // Redirect to homepage after delay
    useEffect(() => {
        setTimeout(() => {
            window.location.assign("https://izipack.nl");
        }, 10000);
    }, []);

    return(
        <div className={"col-span-12 md:container w-full h-full bg-white p-6 md:px-0"}>
            <div className={"flex justify-center mt-3 mb-6 md:mb-12"}>
                <img src={IzipackLogo} alt={"Izipack logo"} />
            </div>

            <div className={"flex flex-col justify-center gap-4"}>
                <div className={"flex md:flex-col justify-center text-center text-2xl md:text-4xl"}>
                    {t("step_5.thank_you")}{windowWidth >= 768 ? (<br/>) : " "}{t("step_5.success")}
                </div>
                <div className={"flex md:flex-col text-center text-gray-text"}>
                    {t("step_5.label_email")}{windowWidth >= 768 ? (<br/>) : " "}{t("step_5.spam")}
                </div>
            </div>

            <div className={"flex justify-center items-center h-96"}>
                <PaymentIllustration />
            </div>
            <div className={"flex justify-center"}>
                <div className={"w-48 text-center text-gray-text"}>
                    {t("step_5.redirection")}
                </div>
            </div>
        </div>
    )
}

export {Step5}
