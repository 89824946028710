import {StepsHeader} from "../../components/Steps/StepsHeader";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Map} from "../../components/Map/map";
import {ReactComponent as CheckIcon} from "../../assets/icon-check.svg";
import {ReactComponent as PrinterIcon} from "../../assets/icon-printer.svg";
import {ReactComponent as EditIcon} from "../../assets/icon-edit.svg";
import { center, colors } from "../../global/constants";
import {Overlay} from "../../components/Overlay";
import {MapOverlay} from "../../components/Map/MapOverlay";
import {SummaryBlock} from "../../components/Steps/SummaryBlock";
import {StepsFooter} from "../../components/Steps/StepsFooter";
import {ReactComponent as StepsIllustration} from "../../assets/steps-illustration.svg";
import {Button, ButtonTypeEnum} from "../../components/Button";
import {useReactToPrint} from "react-to-print";
import {useStore, useStoreActions, useStoreState} from "../../store/hooks";
import {sendPackage} from "../../services/api";
import {MobileSummaryBlock} from "../../components/Steps/SummaryBlock/Mobile";
import {StateMapper} from "easy-peasy";
import {CentralMapOverlay} from "../../components/Map/MapOverlay/Central";
import {filterStations} from "../../services/Input/PostalInput";
import { useNavigate } from "react-router-dom";

/* Summary step */
const Step4 = () => {
    const {t} = useTranslation("steps");
    const tc = useTranslation("components").t;

    const navigate = useNavigate();

    const { setStep } = useStoreActions(actions => actions.StepsModel);

    const store = useStore();
    const stepsStates = useStoreState(state => state.StepsModel);
    const senderStore = stepsStates.sender;
    const receiverStore = stepsStates.receiver;
    const packageStore = stepsStates.package;
    const hubStore = packageStore.hub;

    const { stations, filteredStations } = useStoreState(store => store.LocationsModel);
    const { setFilteredStations } = useStoreActions(actions => actions.LocationsModel);

    const [showMapOverlay, setShowMapOverlay] = useState(false);

    const receiptRef = useRef(null);

    const renderText = (value: string | undefined, uppercase = false) => {
        if (value === "" || value === undefined || value === null) return "\u00A0";
        if (uppercase) return value.toUpperCase();
        return value;
    }

    const printReceipt = useReactToPrint({
        content: () => receiptRef.current
    });

    const getBlockContent = (store: StateMapper<any>) => (
        <div className={"text-gray-text ml-5"}>
            <div>{renderText(store.name)}</div>
            <div>
                {renderText(store.street)}{"\u00A0"}
                {renderText(store.number)},{"\u00A0"}
                {renderText(store.postcode)}
            </div>
            <div>{renderText(store.city)} ({renderText(store.country, true)})</div>
            <div>{renderText(store.email)}</div>
            <div>+({store.prefix.text.replace("+", "")}) {renderText(store.telephone)}</div>
        </div>
    );

    const stepSummaryItem = (stepIndex: number, title: string, children: ReactNode) => (
        <div className={"flex flex-col text-sm gap-2"}>
            <div className={"flex justify-between"}>
                <span className={"font-bold"}>
                    {title}
                </span>
                {/* Edit icon */}
                <div className={"flex w-5 cursor-pointer"} onClick={() => setStep(stepIndex)}>
                    <EditIcon />
                </div>
            </div>
            <div className={"flex flex-col mb-1.5"}>
                {children}
            </div>
        </div>
    );

    const receiptItem = (title: string, children: ReactNode) => (
        <div className={"flex flex-col text-sm gap-2"}>
            <div className={"flex justify-between"}>
                <span className={"font-bold"}>
                    {title}
                </span>
            </div>
            <div className={"flex flex-col mb-1.5"}>
                {children}
            </div>
        </div>
    );

    const sendRequestButton = (
        <div className={"relative w-full md:w-fit h-fit"}>
            <Button buttonType={ButtonTypeEnum.PRIMARY} text={tc("stepsfooter.buttons.continue")}
                    onAsyncClick={async () => {
                        await sendPackage(store)
                            .then((res) => {
                                window.location.replace(res.redirectUrl);
                            })
                            .catch((err) => {
                                console.log(err.message);
                            });
                    }}
            />
        </div>
    );

    useEffect(() => {
        filterStations(hubStore.postalCode, stations, setFilteredStations);
    }, [hubStore]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className={"col-span-12 md:col-span-7 bg-background md:mb-12"}>
                {/* Header */}
                <StepsHeader index={3}>
                    <h1 className="hidden md:block mb-2">{t("step_4.header.title")}</h1>
                    <p className="md:hidden mb-4">{t("step_4.header.text")}</p>
                    <div className={"hidden md:flex flex-row justify-between items-center pb-4"}>
                        <p>{t("step_4.header.text")}</p>
                        <div className={"flex items-center w-fit px-2 py-1 cursor-pointer"} onClick={printReceipt}>
                            <div className={"flex w-5 mr-1.5"}>
                                <PrinterIcon />
                            </div>
                            <span>
                                {t("step_4.labels.print")}
                            </span>
                        </div>
                    </div>
                    <div className={"md:hidden flex justify-between items-center pb-2 md:py-3"}>
                        <h3>{t("step_4.header.title")}</h3>
                        <div className={"flex items-center p-1"} onClick={printReceipt}>
                            <div className={"flex w-5 mr-1.5"}>
                                <PrinterIcon />
                            </div>
                            <span>
                                Print
                            </span>
                        </div>
                    </div>
                </StepsHeader>
                {/* Edit blocks */}
                <div className={"flex flex-col gap-3 p-5 md:px-20 lg:px-28"}>
                    {stepSummaryItem(1, "Sender", getBlockContent(senderStore))}
                    {stepSummaryItem(2, "Receiver", getBlockContent(receiverStore))}
                    {stepSummaryItem(0, "Drop off. Location and address",
                        <div className={"flex gap-3 pt-2"}>
                            {/* Icon */}
                            <div className={"flex items-center"}>
                                <CheckIcon fill={colors.green} />
                            </div>
                            <div className={"flex flex-col"}>
                                <div className={"flex items-center"}>
                                    <span className={"text-sm"}>
                                        {renderText(hubStore.name)}
                                    </span>
                                </div>
                                <div className={"flex items-center"}>
                                    <span className={"text-sm text-gray-text"}>
                                        {renderText(hubStore.postalCode)} {renderText(hubStore.city)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Map */}
                <div className={"relative w-full h-52 cursor-pointer bg-green"} onClick={() => {
                    setShowMapOverlay(true);
                }}>
                    <Map selectedHub={hubStore} overlay={true} zoomControl={false} draggableCursor={"pointer"} markers={[hubStore]} />
                </div>

                {showMapOverlay ? (
                    <>
                        {/* Mobile overlay */}
                        <div className={"md:hidden"}>
                            <Overlay>
                                <MapOverlay
                                    onExit={() => setShowMapOverlay(false)}
                                    hubs={stations}
                                    center={stepsStates.package.hub.id > -1 ? { lat: stepsStates.package.hub.latitude, lng: stepsStates.package.hub.longitude } : center}
                                    zoom={stepsStates.package.hub.id > -1 ? 12 : 8}
                                />
                            </Overlay>
                        </div>
                        {/* Desktop overlay */}
                        <div className={"hidden md:block"}>
                            <CentralMapOverlay
                                //suggestAll={true}
                                center={{ lat: hubStore.latitude, lng: hubStore.longitude }}
                                hubs={stations}
                                onExit={() => {
                                    setShowMapOverlay(false);
                                }}
                            />
                        </div>
                    </>
                ) : null}

                {/* Summary block */}
                <MobileSummaryBlock editLabel={true} button={sendRequestButton}>
                    {/* Info */}
                    <div className={"flex justify-center bg-info bg-opacity-20 gap-2 p-4 mb-4"}>
                        <div className={"flex flex-shrink-0 justify-center items-center w-6 h-6 rounded-full bg-info"}>
                            <span className={"text-white font-bold"}>i</span>
                        </div>
                        <div className={"flex text-sm"}>
                            {t("step_4.labels.info")}
                        </div>
                    </div>
                </MobileSummaryBlock>
            </div>

            {/* Desktop right side */}
            <div className={"hidden md:flex flex-col justify-between h-full col-span-4 col-start-9 bg-white"}>
                {/* Summary block */}
                <SummaryBlock />
                {/* Info */}
                <div className={"hidden md:flex bg-info bg-opacity-20 gap-2 p-4 my-4 lg:ml-5"}>
                    <div className={"flex flex-shrink-0 justify-center items-center w-6 h-6 rounded-full bg-info"}>
                        <span className={"text-white font-bold"}>i</span>
                    </div>
                    <div className={"flex text-sm"}>
                        {t("step_4.labels.info")}
                    </div>
                </div>

                {/* Image SVG */}
                <div className={"flex justify-center items-end w-full h-full mb-12"}>
                    <StepsIllustration />
                </div>
            </div>

            <div className={"hidden md:block mt-24"}>
                <StepsFooter continueButton={sendRequestButton} />
            </div>

            {/* Printable receipt */}
            <div className={"hidden"}>
                <div className={"flex flex-col p-12"} ref={receiptRef}>
                    {/* Title */}
                    <h2 className={"mb-6"}>
                        {tc("summaryblock.package")} {`${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`}
                    </h2>

                    {/* Steps */}
                    {receiptItem("Sender", getBlockContent(senderStore))}
                    {receiptItem("Receiver", getBlockContent(receiverStore))}
                    {receiptItem("Drop off. Location and address",
                        <>
                            <div className={"flex gap-3 pt-2"}>
                                {/* Icon */}
                                <div className={"flex items-center"}>
                                    <CheckIcon fill={colors.green} />
                                </div>
                                <div className={"flex flex-col"}>
                                    <div className={"flex items-center"}>
                                        <span className={"text-sm"}>{hubStore.name}</span>
                                    </div>
                                    <div className={"flex items-center"}>
                                        <span className={"text-sm text-gray-text"}>{hubStore.postalCode} {hubStore.city}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {/* Receipt summary */}
                    <div className={"flex flex-col h-fit bg-white p-6 mt-6 shadow-lg"}>
                        {/* Pricing */}
                        <div className={"flex flex-col gap-2"}>
                            <div className={""}>
                                <label className={"text-sm font-bold"}>{tc("summaryblock.your_price")}</label>
                            </div>
                            <div className={"flex flex-col border-b-1 border-light-grey pl-5 gap-1 pb-2"}>
                                <label className={"text-sm font-semibold"}>{tc("summaryblock.package")} - {packageStore.destination.text}</label>
                                <div className={"flex justify-between"}>
                                    <label className={"text-sm"}>{tc("summaryblock.package")} {packageStore.packageType.name}</label>
                                    <label className={"text-sm"}>€ {packageStore.price}</label>
                                </div>
                            </div>
                            <div className={"flex justify-between"}>
                                <label className={"text-sm"}>
                                    <span className={"font-bold text-base text-green"}>{tc("summaryblock.total")}</span> {tc("summaryblock.tax")}
                                </label>
                                <label className={"text-lg font-bold text-right w-1/4 lg:w-auto"}>€ {packageStore.price}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Step4}
