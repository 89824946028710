import * as yup from "yup";
import { Entries } from "../Package";
import { TFunction } from "react-i18next";

const inputStepValidationSchema = yup.object({
    name: yup.string().required("Name is required")
        .matches(/[a-zA-Z]{2,} [a-zA-Z]{2,}/g, "Name must consist of first and last name"),
    postcode: yup.string().required("Postcode is required")
        .matches(/^[0-9]{4,}( )?[a-z]*$/i, "Invalid postcode"),
    number: yup.string().required("Number is required")
        .matches(/[0-9]+[a-zA-Z]*/g, "Invalid number"),
    street: yup.string().required("Street is required"),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    telephone: yup.string().required("Telephone is required")
        .matches(/^(0)?6[0-9]{8}$/g, "Invalid phone number"),
    email: yup.string().required("E-mail is required")
        .email("Invalid e-mail address")
});

export const validateInput = async (value: string, valueName: string, setErrorState: (any: string) => void, setValidState: (any: boolean) => void) => {
    let error = false;
    await inputStepValidationSchema.validateAt(valueName, { [valueName]: value })
        .catch((err) => {
            error = true;
            setErrorState(err.message);
        });
    setValidState(!error);
};

export const validateInputValue = async (value: string, valueName: string) => {
    let error = false;
    await inputStepValidationSchema.validateAt(valueName, { [valueName]: value })
        .catch(() => error = true);
    return !error;
};

export interface FindPlaceFromQueryResult {
    formatted_address: "",
    geometry: {
        location: {
            lat: () => number,
            lng: () => number
        },
        viewport: object,
    },
    html_attributions: any[],
    name: string
}

export const validateAddress = async (service: google.maps.places.PlacesService | undefined, city: string, zipcode: string, number: string, street: string, country: string, translations: TFunction) => {
    if (!service) return Promise.reject("Google Places API service is not defined. Try again.");

    return new Promise((resolve) => {
        service.findPlaceFromQuery(
            {
                query: `${street} ${number} ${city}`,
                fields: ["formatted_address", "geometry", "name"]
            },
            (results: google.maps.places.PlaceResult[] | null, status: google.maps.places.PlacesServiceStatus) => {
                if (status !== "OK") resolve({ warning: true });

                // @ts-ignore
                const chunks = results[0].formatted_address.split(/[ ,]+/);

                if (chunks.length !== 6) {
                    resolve({ warning: true });
                    return;
                }

                const resStreet = chunks[0];
                const resNumber = chunks[1];
                const resZipcode = chunks[2] + chunks[3];
                const resCity = chunks[4];
                const resCountry = chunks[5];

                const englishCountry = translations(country);

                if (resStreet.toLowerCase() !== street.toLowerCase()) resolve({ warning: true });
                if (resNumber.toLowerCase() !== number.toLowerCase()) resolve({ warning: true });
                if (resZipcode.toLowerCase() !== zipcode.toLowerCase().replaceAll(/ /g, "")) resolve({ warning: true });
                if (resCity.toLowerCase() !== city.toLowerCase()) resolve({ warning: true });
                if (!englishCountry.toLowerCase().includes(resCountry.split(/[ ,]+/g).reverse()[0].toLowerCase())) resolve({ warning: true });

                resolve({ warning: false });
            }
        );
    });
};

export const getPlaceChunks = async (service: google.maps.places.PlacesService | undefined, query: string) => new Promise((resolve) => {
    if (!service) resolve([]);
    if (service) service.findPlaceFromQuery(
        {
            query: `${query}`,
            fields: ["formatted_address"]
        },
        (results: google.maps.places.PlaceResult[] | null, status: google.maps.places.PlacesServiceStatus) => {
            if (status !== "OK") {
                resolve([]);
                return;
            }

            // @ts-ignore
            resolve(results[0].formatted_address.split(/[ ,]+/));
        }
    );
});

export const getPrefixes = () => {
    const prefixes = [];
    const countries = require("../../../assets/data/countries.json");
    for (const [key, value] of Object.entries(countries) as Entries<typeof countries>) {
        prefixes.push({
            value: value.prefix,
            symbol: key,
            text: value.prefix
        });
    }

    return prefixes;
}

