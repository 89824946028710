import {ReactComponent as CrossIcon} from "../../../assets/cross.svg";
import {ReactComponent as LogoIcon} from "../../../assets/logo-icon.svg";
import {Map} from "../map";
import React, { useEffect, useState } from "react";
import {Button, ButtonTypeEnum} from "../../Button";
import {useStoreActions, useStoreState} from "../../../store/hooks";
import {StationDto} from "../../../models/station.dto";

interface MapOverlayProps {
    onExit?: () => void,
    selectedHub?: number,
    hubs?: StationDto[],
    center?: {
        lat: number,
        lng: number
    },
    zoom?: number
}

const MapOverlay = ({ onExit, hubs, center, zoom }: MapOverlayProps) => {
    const hub = useStoreState(state => state.StepsModel.package.hub);
    const { setHub } = useStoreActions(actions => actions.StepsModel.package);

    const [highlightedHub, setHighlightedHub] = useState<StationDto>(hub);

    return(
        <div className={"flex w-full h-full bg-background"}>
            {/* Exit button positioning */}
            <div className={"absolute flex justify-end w-full h-fit p-7"}>
                <div className={"flex justify-center items-center w-6 h-6 bg-text rounded-full cursor-pointer p-1.5 z-10"}
                     onClick={onExit}>
                    <CrossIcon fill={"white"} />
                </div>
            </div>
            {/* Map component */}
            <div className={"w-full h-full bg-background"}>
                <Map
                    overlay={false}
                    selectedHub={highlightedHub} setSelectedHub={setHighlightedHub}
                    markers={hubs} zoom={zoom}
                    center={highlightedHub.id > -1 ? { lat: highlightedHub.latitude, lng: highlightedHub.longitude } : center} />
            </div>
            {/* Selected info-card */}
            {highlightedHub.id > -1 ? (
                <div className={"absolute flex flex-col bottom-0 w-full h-fit bg-white p-5"}>
                    <div className={"flex items-center gap-2"}>
                        {/* Hub logo */}
                        <div className={"flex w-9 h-9 border-3 border-green rounded-full p-1 pb-1.5"}>
                            <LogoIcon />
                        </div>
                        <div className={"flex flex-col"}>
                            <label className={"font-bold"}>{highlightedHub.name}</label>
                            <label className={"text-sm text-gray-text"}>{highlightedHub.postalCode} {highlightedHub.city}</label>
                        </div>
                    </div>
                    {/* Button */}
                    <div className={"mt-6 mb-3"}>
                        <Button buttonType={ButtonTypeEnum.PRIMARY} text={"Choose location"} onClick={() => {
                            setHub(highlightedHub);
                            if (onExit) onExit();
                        }} />
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export {MapOverlay}
