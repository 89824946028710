import {Entries} from "../Package";

export const getPrice = (destination: string, packagetype: string) => {
    let price = 0;
    const countries = require("../../../assets/data/pricing.json");
    for (const [key, value] of Object.entries(countries) as Entries<typeof countries>) {
        if (key === destination) {
            price = value[packagetype.toLowerCase()];
        }
    }

    if (price % 1 != 0) {
        return price.toFixed(2).replace(".", ",");
    } else {
        return price.toString();
    }
}
