import React from "react";
import {Button, ButtonTypeEnum} from "../../Button";
import {useStoreActions, useStoreState} from "../../../store/hooks";
import {useTranslation} from "react-i18next";

interface StepsFooterProps {
    backButton?: JSX.Element,
    continueButton?: JSX.Element
}

const StepsFooter = ({ backButton, continueButton }: StepsFooterProps) => {
    const {t} = useTranslation("components");

    const step = useStoreState(store => store.StepsModel.step);
    const setStep = useStoreActions((actions) => actions.StepsModel.setStep);

    return(
        <div className={"fixed flex w-full h-24 left-0 bottom-0 bg-white shadow-top z-8"}>
            <div className={"container flex justify-end items-center gap-5"}>
                {step > 0 ? (
                    <>
                        {!backButton ? (
                            <div className={"w-fit h-fit"}>
                                <Button buttonType={ButtonTypeEnum.OUTLINE} text={t("stepsfooter.buttons.back")} onClick={() => setStep(step - 1)} />
                            </div>
                        ) : (
                            <>
                                {backButton}
                            </>
                        )}
                    </>
                ) : null}
                {!continueButton ? (
                    <div className={"w-fit h-fit"}>
                        <Button buttonType={ButtonTypeEnum.PRIMARY} text={t("stepsfooter.buttons.continue")} onClick={() => setStep(step + 1)} />
                    </div>
                ) : (
                    <>
                        {continueButton}
                    </>
                )}
            </div>
        </div>
    )
}

export {StepsFooter}
