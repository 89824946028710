import React, { useEffect, useRef, useState } from "react";
import { Input, InputColor, InputForwardRefProps, InputType } from "../../Input";
import { Select, SelectDirection, SelectForwardRefProps, SelectObject } from "../../Select";
import { useTranslation } from "react-i18next";
import { getPlaceChunks, getPrefixes, validateInput, validateInputValue } from "../../../services/Steps/InputStep";
import { smoothScroll } from "../../../global/constants";
import { getDestinations } from "../../../services/Steps/Package";
import { useStoreState } from "../../../store/hooks";

interface InputStepProps {
    service: google.maps.places.PlacesService | undefined
    touched: boolean,
    setTouched: (value: boolean) => void,
    name: string,
    setName: (value: string) => void,
    nameTouched: boolean,
    setNameTouched: (value: boolean) => void,
    postcode: string,
    setPostcode: (value: string) => void,
    postcodeTouched: boolean,
    setPostcodeTouched: (value: boolean) => void,
    number: string,
    setNumber: (value: string) => void,
    numberTouched: boolean,
    setNumberTouched: (value: boolean) => void,
    street: string,
    setStreet: (value: string) => void,
    streetTouched: boolean,
    setStreetTouched: (value: boolean) => void,
    city: string,
    setCity: (value: string) => void,
    cityTouched: boolean,
    setCityTouched: (value: boolean) => void,
    country: string,
    setCountry: (value: string) => void,
    countryTouched: boolean,
    setCountryTouched: (value: boolean) => void,
    prefix?: SelectObject,
    setPrefix: (value: SelectObject) => void,
    prefixTouched?: boolean,
    setPrefixTouched?: (value: boolean) => void,
    telephone: string,
    setTelephone: (value: string) => void,
    telephoneTouched: boolean,
    setTelephoneTouched: (value: boolean) => void,
    email: string,
    setEmail: (value: string) => void,
    emailTouched: boolean,
    setEmailTouched: (value: boolean) => void,
    setValidated: (value: boolean) => void
}

const InputStep = ({
                       service,
                       touched, setTouched,
                       name, setName,
                       nameTouched, setNameTouched,
                       postcode, setPostcode,
                       postcodeTouched, setPostcodeTouched,
                       number, setNumber,
                       numberTouched, setNumberTouched,
                       street, setStreet,
                       streetTouched, setStreetTouched,
                       city, setCity,
                       cityTouched, setCityTouched,
                       country, setCountry,
                       countryTouched, setCountryTouched,
                       prefix, setPrefix,
                       prefixTouched, setPrefixTouched,
                       telephone, setTelephone,
                       telephoneTouched, setTelephoneTouched,
                       email, setEmail,
                       emailTouched, setEmailTouched,
                       setValidated
                    }:
                       InputStepProps) => {
    const {t, i18n} = useTranslation("steps");
    const tc = useTranslation("countries").t;

    const { step } = useStoreState(store => store.StepsModel);

    const prefixes = getPrefixes();
    const destinations = getDestinations(tc);

    // Add The Netherlands as sender's country of origin
    if (step == 1) destinations.push({
        value: "nl",
        symbol: "nl",
        text: i18n.language === "nl" ? "Nederland" : "The Netherlands"
    });
    destinations.sort((a, b) => a.value.localeCompare(b.value));

    const packageDestination = useStoreState(state => state.StepsModel.package.destination);

    const nameRef = useRef<InputForwardRefProps>(null);
    const postcodeRef = useRef<InputForwardRefProps>(null);
    const numberRef = useRef<InputForwardRefProps>(null);
    const streetRef = useRef<InputForwardRefProps>(null);
    const cityRef = useRef<InputForwardRefProps>(null);
    const countryRef = useRef<SelectForwardRefProps>(null);
    const prefixRef = useRef<SelectForwardRefProps>(null);
    const telephoneRef = useRef<InputForwardRefProps>(null);
    const emailRef = useRef<InputForwardRefProps>(null);

    const [streetDisabled, setStreetDisabled] = useState(false); // Set default value to true, if address autocomplete is enabled
    const [cityDisabled, setCityDisabled] = useState(false); // Set default value to true, if address autocomplete is enabled

    const [awaitingStreet, setAwaitingStreet] = useState(true);
    const [awaitingCity, setAwaitingCity] = useState(true);

    const [nameValidated, setNameValidated] = useState(false);
    const [postcodeValidated, setPostcodeValidated] = useState(false);
    const [numberValidated, setNumberValidated] = useState(false);
    const [streetValidated, setStreetValidated] = useState(false);
    const [cityValidated, setCityValidated] = useState(false);
    const [telephoneValidated, setTelephoneValidated] = useState(false);
    const [emailValidated, setEmailValidated] = useState(false);

    const [nameError, setNameError] = useState("");
    const [postcodeError, setPostcodeError] = useState("");
    const [numberError, setNumberError] = useState("");
    const [streetError, setStreetError] = useState("");
    const [cityError, setCityError] = useState("");
    const [telephoneError, setTelephoneError] = useState("");
    const [emailError, setEmailError] = useState("");

    // On mounting of component, set input values and validate
    useEffect(() => {
        if (nameRef.current) nameRef.current.setInputValue(name);
        if (postcodeRef.current) postcodeRef.current.setInputValue(postcode);
        if (numberRef.current) numberRef.current.setInputValue(number);
        if (streetRef.current) streetRef.current.setInputValue(street);
        if (cityRef.current) cityRef.current.setInputValue(city);
        if (telephoneRef.current) telephoneRef.current.setInputValue(telephone);
        if (emailRef.current) emailRef.current.setInputValue(email);

        if (touched) {
            validateInput(name, "name", setNameError, setNameValidated); // Validate name
            validateInput(postcode, "postcode", setPostcodeError, setPostcodeValidated); // Validate postcode
            validateInput(number, "number", setNumberError, setNumberValidated); // Validate number
            validateInput(street, "street", setStreetError, setStreetValidated); // Validate street
            validateInput(city, "city", setCityError, setCityValidated); // Validate city
            validateInput(telephone, "telephone", setTelephoneError, setTelephoneValidated); // Validate telephone
            validateInput(email, "email", setEmailError, setEmailValidated); // Validate email
        }
    }, []);

    // Update validation
    useEffect(() => {
        if (nameValidated && postcodeValidated && numberValidated && streetValidated && cityValidated && telephoneValidated && emailValidated) {
            if (setValidated) setValidated(true);
        } else {
            if (setValidated) setValidated(false);
        }
    }, [nameValidated, postcodeValidated, numberValidated, streetValidated, cityValidated, telephoneValidated, emailValidated]);

    /*
        Address auto complete
     */
    // Postcode
    /*useEffect(() => {
        const fetchPlace = async () => {
            const chunks = await getPlaceChunks(service, postcode) as string[];
            if (!chunks || chunks.length === 0) {
                setAwaitingCity(false);
                return;
            }

            if (cityRef.current && city === "") {
                if (await validateInputValue(chunks[2], "city")) {
                    cityRef.current.setInputValue(chunks[2]);
                    setCityValidated(true);
                    setCityError("");
                } else {
                    setAwaitingCity(false);
                }
            } else {
                setAwaitingCity(false);
            }

            if (countryRef.current && !countryTouched) {
                if (await validateInputValue(chunks[3], "country")) {
                    const filteredCountry = destinations.filter(dest => {
                        if (dest.text.toLowerCase().includes(chunks[3].split(/[ ,]+/g).reverse()[0].toLowerCase())) return dest
                    });
                    if (filteredCountry.length === 0) return;

                    const countryCode = filteredCountry[0].value;

                    const englishCountry = tc(countryCode);
                    if (englishCountry.toLowerCase().includes(chunks[3].split(/[ ,]+/g).reverse()[0].toLowerCase())) {
                        countryRef.current.setSelectValue({
                            value: countryCode,
                            symbol: countryCode,
                            text: englishCountry
                        });
                    }
                }
            }
        }

        if (postcode !== "") fetchPlace();
    }, [postcodeValidated]);*/
    // Number
    /*useEffect(() => {
        const fetchPlace = async () => {
            const chunks = await getPlaceChunks(service, `${postcode}%20%${number}`) as string[];
            if (!chunks || chunks.length === 0) {
                setAwaitingStreet(false);
                return;
            }

            if (streetRef.current && street === "") {
                if (await validateInputValue(chunks[0], "street")) {
                    streetRef.current.setInputValue(chunks[0]);
                    setStreetValidated(true);
                    setStreetError("");
                } else {
                    setAwaitingStreet(false);
                }
            } else {
                setAwaitingStreet(false);
            }
        }

        if (number !== "" && postcodeValidated && postcode !== "") fetchPlace();
    }, [numberValidated]);*/
    // Street (NOT USED)
    /*useEffect(() => {
        const fetchPlace = async () => {
            const chunks = await getPlaceChunks(service, street) as string[];
            if (!chunks || chunks.length === 0) return;

            if (postcodeRef.current && postcode === "") {
                if (await validateInputValue(chunks[1] + chunks[2], "postcode")) {
                    postcodeRef.current.setInputValue(chunks[1] + chunks[2]);
                    setPostcodeValidated(true);
                    setPostcodeError("");
                }
            }
        }

        if (street !== "") fetchPlace();
    }, [streetValidated]);*/

    // Enable street and city field when postcode and number fields have been validated, and potential autocomplete has finished
    /*useEffect(() => {
        if (!awaitingStreet && !awaitingCity) {
            setStreetDisabled(false);
            setCityDisabled(false);
        }
    }, [street, city, awaitingStreet, awaitingCity]);*/

    /*
        Automatic input validation
     */
    const delay = 1000;
    // Name
    useEffect(() => {
        if (!nameTouched) return;
        const timeout = setTimeout(() => {
            validateInput(name, "name", setNameError, setNameValidated);
        }, delay);

        // If called again, cancel previous timeout
        return () => {
            clearTimeout(timeout);
        }
    }, [name]);
    // Postcode
    useEffect(() => {
        if (!postcodeTouched) return;
        const timeout = setTimeout(() => {
            validateInput(postcode, "postcode", setPostcodeError, setPostcodeValidated);
        }, delay);

        // If called again, cancel previous timeout
        return () => {
            clearTimeout(timeout);
        }
    }, [postcode]);
    // Number
    useEffect(() => {
        if (!numberTouched) return;
        const timeout = setTimeout(() => {
            validateInput(number, "number", setNumberError, setNumberValidated);
        }, delay);

        // If called again, cancel previous timeout
        return () => {
            clearTimeout(timeout);
        }
    }, [number]);
    // Street
    useEffect(() => {
        if (street !== "") setAwaitingStreet(false);
        if (!streetTouched) return;
        const timeout = setTimeout(() => {
            validateInput(street, "street", setStreetError, setStreetValidated);
        }, delay);

        // If called again, cancel previous timeout
        return () => {
            clearTimeout(timeout);
        }
    }, [street]);
    // City
    useEffect(() => {
        if (city !== "") setAwaitingCity(false);
        if (!cityTouched) return;
        const timeout = setTimeout(() => {
            validateInput(city, "city", setCityError, setCityValidated);
        }, delay);

        // If called again, cancel previous timeout
        return () => {
            clearTimeout(timeout);
        }
    }, [city]);
    // Telephone
    useEffect(() => {
        if (!telephoneTouched) return;
        const timeout = setTimeout(() => {
            validateInput(telephone, "telephone", setTelephoneError, setTelephoneValidated);
        }, delay);

        // If called again, cancel previous timeout
        return () => {
            clearTimeout(timeout);
        }
    }, [telephone]);
    // E-mail
    useEffect(() => {
        if (!emailTouched) return;
        const timeout = setTimeout(() => {
            validateInput(email, "email", setEmailError, setEmailValidated);
        }, delay);

        // If called again, cancel previous timeout
        return () => {
            clearTimeout(timeout);
        }
    }, [email]);

    return(
        <div className={"flex flex-col bg-background gap-7 p-6"}>
            {/* Name */}
            <div className={"relative flex w-full h-18"} onClick={(e) => smoothScroll(e.target as HTMLElement)}>
                <Input placeholder={"E.g. Louis van Diesel"} label={t("step_2.labels.name")}
                       color={InputColor.WHITE} passValueChange={setName} ref={nameRef}
                       validated={nameValidated && nameError === ""} error={nameError !== ""} errorMessage={nameError}
                       onClick={() => {
                           setNameTouched(true);
                           setTouched(true);
                       }}
                       onFocus={() => setNameTouched(true)}
                       onChange={() => {
                           setNameError("");
                           setNameValidated(false);
                       }} onBlur={() => validateInput(name, "name", setNameError, setNameValidated)} />
            </div>
            {/* Country */}
            <div className={"relative flex w-full h-18"}>
                <Select label={t("step_2.labels.country")}
                        options={destinations} ref={countryRef}
                        handleChange={(e) => setCountry(e.value)}
                        onClick={() => {
                            setCountryTouched(true);
                            setTouched(true);
                        }}
                        defaultValue={step === 1 ? {
                            value: "nl",
                            symbol: "nl",
                            text: tc("nl")
                        } : (packageDestination.symbol ? {
                            value: packageDestination.value,
                            symbol: packageDestination.symbol,
                            text: tc(packageDestination.value)
                        } : undefined)}
                        direction={SelectDirection.DOWN}
                />
            </div>
            {/* Postcode and number */}
            <div className={"flex w-full h-18 gap-3"}>
                <div className={"relative w-1/2"} onClick={(e) => smoothScroll(e.target as HTMLElement)}>
                    <Input placeholder={"E.g. 4352SH"} label={t("step_2.labels.postcode")}
                           color={InputColor.WHITE} passValueChange={setPostcode} ref={postcodeRef}
                           validated={postcodeValidated && postcodeError === ""} error={postcodeError !== ""} errorMessage={postcodeError}
                           onClick={() => {
                               setPostcodeTouched(true);
                               setTouched(true);
                           }}
                           onFocus={() => setPostcodeTouched(true)}
                           onChange={() => {
                               setPostcodeError("");
                               setPostcodeValidated(false);
                           }} onBlur={() => validateInput(postcode, "postcode", setPostcodeError, setPostcodeValidated)} />
                </div>
                <div className={"relative w-1/2"} onClick={(e) => smoothScroll(e.target as HTMLElement)}>
                    <Input placeholder={"E.g. 14-18"} label={t("step_2.labels.number")}
                           color={InputColor.WHITE} passValueChange={setNumber} ref={numberRef}
                           validated={numberValidated && numberError === ""} error={numberError !== ""} errorMessage={numberError}
                           onClick={() => {
                               setNumberTouched(true);
                               setTouched(true);
                           }}
                           onFocus={() => setNumberTouched(true)}
                           onChange={() => {
                               setNumberError("");
                               setNumberValidated(false);
                           }} onBlur={() => validateInput(number, "number", setNumberError, setNumberValidated)} />
                </div>
            </div>
            {/* Street */}
            <div className={"relative flex w-full h-18"} onClick={(e) => smoothScroll(e.target as HTMLElement)}>
                <Input placeholder={"E.g. Van der Palmstraat"} label={t("step_2.labels.street")}
                       color={InputColor.WHITE} passValueChange={setStreet} ref={streetRef} disabled={streetDisabled}
                       validated={streetValidated && streetError === ""} error={streetError !== ""} errorMessage={streetError}
                       onClick={() => {
                           setStreetTouched(true);
                           setTouched(true);
                       }}
                       onFocus={() => setStreetTouched(true)}
                       onChange={() => {
                           setStreetError("");
                           setStreetValidated(false);
                       }} onBlur={() => validateInput(street, "street", setStreetError, setStreetValidated)} />
            </div>
            {/* City */}
            <div className={"relative flex w-full h-18"} onClick={(e) => smoothScroll(e.target as HTMLElement)}>
                <Input placeholder={"E.g. Delft"} label={t("step_2.labels.city")}
                       color={InputColor.WHITE} passValueChange={setCity} ref={cityRef} disabled={cityDisabled}
                       validated={cityValidated && cityError === ""} error={cityError !== ""} errorMessage={cityError}
                       onClick={() => {
                           setCityTouched(true);
                           setTouched(true);
                       }}
                       onFocus={() => setCityTouched(true)}
                       onChange={() => {
                           setCityError("");
                           setCityValidated(false);
                       }} onBlur={() => validateInput(city, "city", setCityError, setCityValidated)} />
            </div>
            {/* Telephone */}
            <div className={"flex w-full h-18 gap-3"}>
                <div className={"relative w-2/5"} onClick={(e) => smoothScroll(e.target as HTMLElement)}>
                    <Select label={t("step_2.labels.prefix")}
                            options={prefixes}
                            handleChange={setPrefix} ref={prefixRef}
                            onClick={() => {
                                if (setPrefixTouched) setPrefixTouched(true);
                                setTouched(true);
                            }}
                            defaultValue={step === 1 ? {
                                value: "+31",
                                symbol: "nl",
                                text: tc("+31")
                            } : (country ? prefixes.find(prefix => prefix.symbol === packageDestination.symbol) : undefined)}
                            tight
                    />

                </div>
                <div className={"relative w-3/5"} onClick={(e) => smoothScroll(e.target as HTMLElement)}>
                    <Input placeholder={"E.g. 612345678"} label={t("step_2.labels.telephone")} ref={telephoneRef}
                           color={InputColor.WHITE} type={InputType.NUMBER} passValueChange={setTelephone}
                           validated={telephoneValidated && telephoneError === ""} error={telephoneError !== ""} errorMessage={telephoneError}
                           onClick={() => {
                               setTelephoneTouched(true);
                               setTouched(true)
                           }}
                           onFocus={() => setTelephoneTouched(true)}
                           onChange={() => {
                               setTelephoneError("");
                               setTelephoneValidated(false);
                           }} onBlur={() => validateInput(telephone, "telephone", setTelephoneError, setTelephoneValidated)} />
                </div>
            </div>
            {/* E-mail */}
            <div className={"relative flex w-full h-18"} onClick={(e) => smoothScroll(e.target as HTMLElement)}>
                <Input placeholder={"E.g. louis@izipack.com"} label={t("step_2.labels.email")}
                       color={InputColor.WHITE} passValueChange={setEmail} ref={emailRef}
                       validated={emailValidated && emailError === ""} error={emailError !== ""} errorMessage={emailError}
                       onClick={() => {
                           setEmailTouched(true);
                           setTouched(true);
                       }}
                       onFocus={() => setEmailTouched(true)}
                       onChange={() => {
                           setEmailError("");
                           setEmailValidated(false);
                       }} onBlur={() => validateInput(email, "email", setEmailError, setEmailValidated)} />
            </div>
        </div>
    )
}

export {InputStep}
