import {Button, ButtonTypeEnum} from "../../../Button";
import React from "react";
import {useStoreActions, useStoreState} from "../../../../store/hooks";
import {ReactI18NextChild, useTranslation} from "react-i18next";
import {ReactComponent as EditIcon} from "../../../../assets/icon-edit.svg";

interface MobileSummaryBlockProps {
    button?: JSX.Element,
    editLabel?: boolean
    children?: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> |
        React.ReactFragment | React.ReactPortal | Iterable<ReactI18NextChild> | null | undefined
}

const MobileSummaryBlock = ({ button, editLabel = false, children }: MobileSummaryBlockProps) => {
    const {t} = useTranslation("components");

    const { setStep } = useStoreActions(actions => actions.StepsModel);
    const { hub, packageType, destination, price } = useStoreState(state => state.StepsModel.package);

    return (
        <div className={"md:hidden flex flex-col bg-white p-6"}>
            {/* Pricing */}
            <div className={"flex flex-col gap-2"}>
                <div className={"flex justify-between"}>
                    <label className={"text-sm font-bold"}>{t("summaryblock.your_price")}</label>
                    {editLabel ? (
                        <div className={"flex w-5"} onClick={() => setStep(0)}>
                            <EditIcon />
                        </div>
                    ): null}
                </div>
                <div className={"flex flex-col border-b-1 border-light-grey pl-5 gap-1 pb-2"}>
                    <label className={"text-sm font-semibold"}>{t("summaryblock.package")} - {destination.text}</label>
                    <div className={"flex justify-between"}>
                        {packageType ? (
                            <label className={"text-sm"}>{t("summaryblock.package")} {packageType.name}</label>
                        ) : null}
                        <label className={"text-sm"}>€ {price}</label>
                    </div>
                </div>
                <div className={"flex justify-between"}>
                    <label className={"text-sm"}>
                        <span className={"font-bold text-base text-green"}>{t("summaryblock.total")}</span> {t("summaryblock.tax")}
                    </label>
                    <label className={"text-lg font-bold"}>€ {price}</label>
                </div>
            </div>

            {/* Button(s) */}
            <div className={"flex flex-col my-7 gap-3"}>
                {/*<Button buttonType="outline" text="+ Add another package" />*/}
                {!button ? (
                    <Button buttonType={ButtonTypeEnum.PRIMARY} text="Continue shipping"
                            disabled={hub.id === -1 || packageType.id === -1} onClick={() => setStep(1)} />
                ) : (
                    <>
                        {button}
                    </>
                )}
            </div>

            {children}
        </div>
    )
}

export {MobileSummaryBlock}
