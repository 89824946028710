import './App.css';
import {useStoreActions, useStoreState} from './store/hooks';
import { Navbar } from "./components/Menu/Navbar";
import { Step1 } from "./feature/Steps/step1";
import { Step2 } from "./feature/Steps/step2";
import { Step3 } from "./feature/Steps/step3";
import { Step4 } from "./feature/Steps/step4";
import { Step5 } from "./feature/Steps/step5";
import { useEffect, useState } from "react";
import {Route, Routes} from "react-router-dom";
import {NotFound} from "./components/404";
import { useLoadScript, GoogleMap } from "@react-google-maps/api";
import { googleMapsLibraries } from "./global/constants";
import { StepError } from "./components/Steps/StepError";

function App() {
    const { isLoaded, loadError } = useLoadScript({
        // @ts-ignore
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        // @ts-ignore
        libraries: googleMapsLibraries
    });

    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>();

    const { step } = useStoreState(store => store.StepsModel) ?? 0;
    const { stations } = useStoreState(store => store.LocationsModel);

    const { fetchStations } = useStoreActions(actions => actions.LocationsModel);

    // Fetch stations on load
    useEffect(() => {
        if (stations.length === 0) fetchStations();
    }, []);

    // Set up Google Places API service after script is loaded
    useEffect(() => {
        if (isLoaded) {
            const mapElem = document.getElementById("map");
            if (!mapElem) return;
            const map = new google.maps.Map(mapElem);
            if (map) setPlacesService(new google.maps.places.PlacesService(map));
        }
    }, [isLoaded]);

    return isLoaded ? (
        <div>
            <GoogleMap id={"map"} mapContainerClassName={"hidden"} />
            <div className="md:container grid grid-cols-12 bg-white">
                <div className={"col-span-12 h-fit"}>
                    <Navbar />
                </div>
                <Routes>
                    <Route path={"/"} element={
                        step === 0 ? (
                            <Step1 placesService={placesService} />
                        ) : step === 1 ? (
                            <Step2 placesService={placesService} />
                        ) : step === 2 ? (
                            <Step3 placesService={placesService} />
                        ) : step === 3 ? (
                            <Step4 />
                        ) : null
                    } />
                    <Route path={"/success"} element={<Step5 />} />
                    <Route path={"/error"} element={<StepError />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </div>
    ) : null;
}

export default App;
