import {useTranslation} from "react-i18next";

const NotFound = () => {
    const {t} = useTranslation("components");

    return (
        <div className={"col-span-12 flex justify-center items-center w-full h-fit"}>
            <div className={"p-12 mt-24"}>
                <div className={"text-xl text-center"}>
                    404: {t("404.exist")}
                </div>
            </div>
        </div>
    )
}

export { NotFound }
