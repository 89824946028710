import {Action, action} from "easy-peasy";
import {SelectObject} from "../../components/Select";
import {PackageType} from "../../components/Package";
import {center, rotterdam} from "../../global/constants";
import {StationDto} from "../../models/station.dto";

interface Step1 {
    hub: StationDto,
    setHub: Action<this, StationDto>
    packageType: PackageType,
    setPackageType: Action<this, PackageType>
    destination: SelectObject,
    setDestination: Action<this, SelectObject>,
    price: string,
    setPrice: Action<this, string>
}

interface Step2 {
    touched: boolean,
    setTouched: Action<this, boolean>,
    validated: boolean,
    setValidated: Action<this, boolean>,
    name: string,
    setName: Action<this, string>,
    nameTouched: boolean,
    setNameTouched: Action<this, boolean>,
    postcode: string,
    setPostcode: Action<this, string>,
    postcodeTouched: boolean,
    setPostcodeTouched: Action<this, boolean>,
    number: string,
    setNumber: Action<this, string>,
    numberTouched: boolean,
    setNumberTouched: Action<this, boolean>,
    street: string,
    setStreet: Action<this, string>,
    streetTouched: boolean,
    setStreetTouched: Action<this, boolean>,
    city: string,
    setCity: Action<this, string>,
    cityTouched: boolean,
    setCityTouched: Action<this, boolean>,
    country: string,
    setCountry: Action<this, string>,
    countryTouched: boolean,
    setCountryTouched: Action<this, boolean>,
    prefix: SelectObject,
    setPrefix: Action<this, SelectObject>,
    prefixTouched: boolean,
    setPrefixTouched: Action<this, boolean>,
    telephone: string,
    setTelephone: Action<this, string>,
    telephoneTouched: boolean,
    setTelephoneTouched: Action<this, boolean>,
    email: string,
    setEmail: Action<this, string>,
    emailTouched: boolean,
    setEmailTouched: Action<this, boolean>
}

interface Step3 extends Step2 {}

interface StepsModel {
    step: number,
    setStep: Action<this, number>,
    package: Step1,
    sender: Step2,
    receiver: Step3
}

const StepsModel: StepsModel = {
    step: 0,
    setStep: action((state, payload) => {
        state.step = payload;
    }),
    package: {
        hub: {
            id: -1,
            name: "",
            address: "",
            street: "",
            houseNumber: -1,
            houseAddition: "",
            houseLetter: "",
            postalCode: "",
            city: "",
            country: "",
            latitude: -1,
            longitude: -1,
            active: "",
            availableForDropoff: false,
            availableForPickup: false,
            distanceInKm: -1,
            is_service_point: -1,
            mon_from: null,
            mon_to: null,
            tue_from: "",
            tue_to: "",
            wed_from: "",
            wed_to: "",
            thu_from: "",
            thu_to: "",
            fri_from: "",
            fri_to: "",
            sat_from: "",
            sat_to: "",
            sun_from: "",
            sun_to: "",
            mon_break_from: null,
            mon_break_to: null,
            tue_break_from: null,
            tue_break_to: null,
            wed_break_from: null,
            wed_break_to: null,
            thu_break_from: null,
            thu_break_to: null,
            fri_break_from: null,
            fri_break_to: null,
            sat_break_from: null,
            sat_break_to: null,
            sun_break_from: null,
            sun_break_to: null,
            iziServiceAreaId: -1,
            iziLocationTypeId: -1
        },
        setHub: action((state, payload) => {
            state.hub = payload;
        }),
        packageType: {
            id: -1,
            name: "",
            symbol: "",
            type: ""
        },
        setPackageType: action((state, payload) => {
           state.packageType = payload;
        }),
        destination: {
            value: "",
            symbol: "",
            text: ""
        },
        setDestination: action((state, payload) => {
            state.destination = payload;
        }),
        price: "",
        setPrice: action((state, payload) => {
            state.price = payload;
        })
    },
    sender: {
        touched: false,
        setTouched: action((state, payload) => {
            state.touched = payload;
        }),
        validated: false,
        setValidated: action((state, payload) => {
            state.validated = payload;
        }),
        name: "",
        setName: action((state, payload) => {
            state.name = payload;
        }),
        nameTouched: false,
        setNameTouched: action((state, payload) => {
            state.nameTouched = payload;
        }),
        postcode: "",
        setPostcode: action((state, payload) => {
            state.postcode = payload;
        }),
        postcodeTouched: false,
        setPostcodeTouched: action((state, payload) => {
            state.postcodeTouched = payload;
        }),
        number: "",
        setNumber: action((state, payload) => {
            state.number = payload;
        }),
        numberTouched: false,
        setNumberTouched: action((state, payload) => {
            state.numberTouched = payload;
        }),
        street: "",
        setStreet: action((state, payload) => {
            state.street = payload;
        }),
        streetTouched: false,
        setStreetTouched: action((state, payload) => {
            state.streetTouched = payload;
        }),
        city: "",
        setCity: action((state, payload) => {
            state.city = payload;
        }),
        cityTouched: false,
        setCityTouched: action((state, payload) => {
            state.cityTouched = payload;
        }),
        country: "",
        setCountry: action((state, payload) => {
            state.country = payload;
        }),
        countryTouched: false,
        setCountryTouched: action((state, payload) => {
            state.countryTouched = payload;
        }),
        prefix: {
            value: "",
            symbol: "",
            text: ""
        },
        setPrefix: action((state, payload) => {
            state.prefix = payload;
        }),
        prefixTouched: false,
        setPrefixTouched: action((state, payload) => {
            state.prefixTouched = payload;
        }),
        telephone: "",
        setTelephone: action((state, payload) => {
            state.telephone = payload;
        }),
        telephoneTouched: false,
        setTelephoneTouched: action((state, payload) => {
            state.telephoneTouched = payload;
        }),
        email: "",
        setEmail: action((state, payload) => {
            state.email = payload;
        }),
        emailTouched: false,
        setEmailTouched: action((state, payload) => {
            state.emailTouched = payload;
        })
    },
    receiver: {
        touched: false,
        setTouched: action((state, payload) => {
            state.touched = payload;
        }),
        validated: false,
        setValidated: action((state, payload) => {
            state.validated = payload;
        }),
        name: "",
        setName: action((state, payload) => {
            state.name = payload;
        }),
        nameTouched: false,
        setNameTouched: action((state, payload) => {
            state.nameTouched = payload;
        }),
        postcode: "",
        setPostcode: action((state, payload) => {
            state.postcode = payload;
        }),
        postcodeTouched: false,
        setPostcodeTouched: action((state, payload) => {
            state.postcodeTouched = payload;
        }),
        number: "",
        setNumber: action((state, payload) => {
            state.number = payload;
        }),
        numberTouched: false,
        setNumberTouched: action((state, payload) => {
            state.numberTouched = payload;
        }),
        street: "",
        setStreet: action((state, payload) => {
            state.street = payload;
        }),
        streetTouched: false,
        setStreetTouched: action((state, payload) => {
            state.streetTouched = payload;
        }),
        city: "",
        setCity: action((state, payload) => {
            state.city = payload;
        }),
        cityTouched: false,
        setCityTouched: action((state, payload) => {
            state.cityTouched = payload;
        }),
        country: "",
        setCountry: action((state, payload) => {
            state.country = payload;
        }),
        countryTouched: false,
        setCountryTouched: action((state, payload) => {
            state.countryTouched = payload;
        }),
        prefix: {
            value: "",
            symbol: "",
            text: ""
        },
        setPrefix: action((state, payload) => {
            state.prefix = payload;
        }),
        prefixTouched: false,
        setPrefixTouched: action((state, payload) => {
            state.prefixTouched = payload;
        }),
        telephone: "",
        setTelephone: action((state, payload) => {
            state.telephone = payload;
        }),
        telephoneTouched: false,
        setTelephoneTouched: action((state, payload) => {
            state.telephoneTouched = payload;
        }),
        email: "",
        setEmail: action((state, payload) => {
            state.email = payload;
        }),
        emailTouched: false,
        setEmailTouched: action((state, payload) => {
            state.emailTouched = payload;
        })
    },
}

export default StepsModel;
