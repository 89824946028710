import React, {useEffect} from "react";
import {CentralOverlay} from "../../../Overlay/Central";
import {Map} from "../../map";
import {ReactComponent as LogoIcon} from "../../../../assets/logo-icon-white.svg";
import {ReactComponent as IconCheck} from "../../../../assets/icon-check.svg";
import {ReactComponent as CrossIcon} from "../../../../assets/cross.svg";
import {useStoreActions, useStoreState} from "../../../../store/hooks";
import { StationDto } from "../../../../models/station.dto";
import {sortStations} from "../../../../services/Map/MapOverlay/Central";
import { useTranslation } from "react-i18next";

interface CentralMapOverlayProps {
    onExit: () => void,
    hubs: StationDto[],
    center?: {
        lat: number,
        lng: number
    }
    suggestAll?: boolean,
    nationalZoom?: boolean
}

const CentralMapOverlay = ({ onExit, hubs, center, suggestAll = false, nationalZoom = false }: CentralMapOverlayProps) => {
    const { t } = useTranslation("components");

    const hub = useStoreState(state => state.StepsModel.package.hub);
    const { setHub } = useStoreActions(actions => actions.StepsModel.package);
    const { filteredStations } = useStoreState(actions => actions.LocationsModel);

    const handleSelect = (newHub: StationDto) => {
        onExit(); // Close overlay
        setHub(newHub); // Set selected hub
    }

    const suggestionItem = (newHub: StationDto) => (
        <div className={"relative flex justify-between items-center border-diver border-b p-4 cursor-pointer"}
             onClick={() => handleSelect(newHub)}>
            <div className={"flex items-center gap-2"}>
                {/* Icon */}
                <div className={"absolute flex justify-center items-center w-8 h-8 p-1.5"}>
                    <div className={"z-1"}>
                        <LogoIcon fill={"black"}/>
                    </div>
                    <div className={"absolute flex w-full h-full bg-green opacity-20 rounded-full"}/>
                </div>
                <div className={"flex flex-col ml-12"}>
                    <div className={"text-sm"}>{newHub.name}</div>
                    <div className={"text-sm text-gray-text"}>{newHub.postalCode.substring(0, 4) + " " + newHub.postalCode.substring(4)} {newHub.city}</div>
                </div>
            </div>
            <div className={"relative flex w-6 h-6 ml-3"}>
                <div className={`absolute right-0 flex justify-center items-center w-6 h-6 rounded-full border-1 ${
                    hub.id === newHub.id ? "border-green bg-green" : "border-grey"}`}>
                    {hub.id === newHub.id ? <IconCheck fill={"white"}/> : null}
                </div>
            </div>
        </div>
    );

    return (
        <CentralOverlay>
            <div className={"flex w-full h-full bg-white shadow-2xl"}>
                {/* Options side */}
                <div className={"w-[85%] h-full overflow-y-auto"}>
                    {!suggestAll ? (
                        <>
                            {filteredStations.length === 0 ? (
                                <div className={"p-4"}>{t("map.no_results")}</div>
                            ) : sortStations(filteredStations).map((station) => (
                                <div key={station.id}>
                                    {suggestionItem(station)}
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            {sortStations(hubs).map((station) => (
                                <div key={station.id}>
                                    {suggestionItem(station)}
                                </div>
                            ))}
                        </>
                    )}
                </div>
                {/* Map side */}
                <div className={"relative w-full h-full"}>
                    <Map
                        overlay={false} markers={hubs}
                        center={center}
                        zoom={nationalZoom ? 7 : 13}
                        selectedHub={hub}
                        setSelectedHub={(hub) => {
                            setHub(hub);
                            onExit();
                        }}
                    />
                    {/* Exit button positioning */}
                    <div className={"absolute flex justify-center items-center top-5 right-5 w-6 h-6 bg-text rounded-full cursor-pointer p-1.5 z-10"}
                         onClick={onExit}>
                        <CrossIcon fill={"white"} />
                    </div>
                </div>
            </div>
        </CentralOverlay>
    );
}

export {CentralMapOverlay}
