import React from "react";
import {useStoreActions, useStoreState} from "../../store/hooks";
import {GoogleMap, InfoWindow, Marker, MarkerF, useJsApiLoader} from '@react-google-maps/api';
import {MapMarker} from "./MapMarker";
import {center, colors} from "../../global/constants";
import {ReactComponent as LocationIcon} from "../../assets/icon-location.svg";
import HubIcon from "../../assets/selected-map-hub.svg";
import {StationDto} from "../../models/station.dto";

/*interface MarkerType {
    name: string,
    place: string,
    lat: number,
    lng: number
}*/

interface MapProps {
    selectedHub: StationDto,
    setSelectedHub?: (value: StationDto) => void,
    markers?: StationDto[],
    overlay: boolean,
    zoom?: number,
    zoomControl?: boolean,
    mapTypeControl?: boolean,
    scaleControl?: boolean,
    streetViewControl?: boolean,
    rotateControl?: boolean,
    fullscreenControl?: boolean,
    draggableCursor?: string,
    center?: {
        lat: number,
        lng: number
    }
}

const containerStyle = {
    width: "100%",
    height: "100%"
};

const Map = ({ selectedHub, setSelectedHub, markers, overlay, zoom = 13, zoomControl = true, mapTypeControl = false, scaleControl = true,
                 streetViewControl = false, rotateControl = true, fullscreenControl = false, draggableCursor = "", center }: MapProps) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
    });

    const hub = useStoreState(state => state.StepsModel.package.hub);
    //const { setHub } = useStoreActions(actions => actions.StepsModel.package);

    const handleMarkerClick = (hub: StationDto) => {
        if (setSelectedHub) setSelectedHub(hub);
    };

    return isLoaded ? (
        <>
            {overlay ? (
                <div className={"absolute flex w-full h-full pointer-events-auto z-10"} />
            ) : null}
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={!center ? { lat: selectedHub.latitude, lng: selectedHub.longitude } : { lat: center.lat, lng: center.lng }}
                zoom={zoom}
                clickableIcons={false}
                options={{
                    zoomControl: overlay ? false : zoomControl,
                    mapTypeControl: mapTypeControl,
                    scaleControl: scaleControl,
                    streetViewControl: streetViewControl,
                    rotateControl: rotateControl,
                    fullscreenControl: fullscreenControl,
                    draggableCursor: draggableCursor,
                    keyboardShortcuts: !overlay
                }}
            >
                {markers && markers.map((marker) => {
                    return(
                        <div key={marker.id}>
                            <MapMarker
                                       name={marker.name} place={marker.city}
                                       zipcode={marker.postalCode}
                                       lat={marker.latitude} lng={marker.longitude}
                                       selected={marker.id === hub.id}
                                       onClick={() => handleMarkerClick(marker)}
                            />
                        </div>
                    )
                })}
            </GoogleMap>
        </>
    ) : null;
}

export {Map}
