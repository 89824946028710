import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonTypeEnum } from "../../Button";

interface WarningProps {
    title: string,
    message: string,
    okFunction?: () => void,
    cancelFunction?: () => void
}

const Warning = ({ title, message, okFunction, cancelFunction }: WarningProps) => {
    const { t } = useTranslation("components");

    return (
        <div className={"flex justify-center items-center w-full h-full"}>
            <div className={"flex flex-col items-center gap-3 bg-white shadow p-8"}>
                <div className={"flex"}>
                    <div className={"flex items-center text-yellow mr-2"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                    </div>
                    <div className={"text-yellow text-lg font-bold"}>
                        {t("warning.warning")}
                    </div>
                </div>
                <div className={"flex flex-col text-center p-3"}>
                    <div className={"font-semibold mb-1"}>
                        {title}
                    </div>
                    <div>
                        {message}
                    </div>
                </div>
                <div className={"flex gap-2 mt-2"}>
                    <div className={"w-fit"}>
                        <Button buttonType={ButtonTypeEnum.PRIMARY} text={t("warning.buttons.stay")} onClick={cancelFunction} />
                    </div>
                    <div className={"w-fit"}>
                        <Button buttonType={ButtonTypeEnum.OUTLINE} text={t("warning.buttons.forward")} onClick={okFunction} />
                    </div>
                </div>

            </div>
        </div>
    );
}

export { Warning };
