import {action, Action, thunk, Thunk} from "easy-peasy";
import {urls} from "../../global/constants";
import axios from "axios";
import {getApiEndpoint} from "../../services/api";
import {PackageType} from "../../components/Package";
import {StationDto} from "../../models/station.dto";

interface LocationsModel {
    stations: StationDto[],
    filteredStations: StationDto[],
    setFilteredStations: Action<this, StationDto[]>,
    setStations:  Action<this, StationDto[]>,
    fetchStations: Thunk<this>,
    getStationsByZipcode: Thunk<this>
}

/*export interface StationType {
    id: number,
    "locationTypeId": number,
    "serviceCountryId": number,
    "serviceAreaId": number,
    "name": string,
    "address": string,
    "zipcode": string,
    "city": string,
    "country": string,
    "latitude": number,
    "longitude": number,
    "defaultLocation": boolean,
    "available247": boolean,
    "openingTimes": [ {
        id: number,
        "izipackLocationId": number,
        "dayInWeek": number,
        "openingTime": {
            "ticks": number,
            "days": number,
            "hours": number,
            "milliseconds": number,
            "minutes": number,
            "seconds": number,
            "totalDays": number,
            "totalHours": number,
            "totalMilliseconds": number,
            "totalMinutes": number,
            "totalSeconds": number
        },
        "closingTime": {
            "ticks": number,
            "days": number,
            "hours": number,
            "milliseconds": number,
            "minutes": number,
            "seconds": number,
            "totalDays": number,
            "totalHours": number,
            "totalMilliseconds": number,
            "totalMinutes": number,
            "totalSeconds": number
        },
        "cutOffTime": {
            "ticks": number,
            "days": number,
            "hours": number,
            "milliseconds": number,
            "minutes": number,
            "seconds": number,
            "totalDays": number,
            "totalHours": number,
            "totalMilliseconds": number,
            "totalMinutes": number,
            "totalSeconds": number
        },
        "closed": boolean,
        "createdAt": string,
        "updatedAt": string
    } ]
}*/

const LocationsModel: LocationsModel = {
    stations: [],
    setStations: action((state, payload) => {
        state.stations = payload;
    }),
    filteredStations: [],
    setFilteredStations: action((state, payload) => {
        state.filteredStations = payload;
    }),
    fetchStations: thunk(async (actions) => {
        const data = await axios.get(getApiEndpoint() + "International/dropoffpoints")
            .then(res => res.data);
        actions.setStations(data);
    }),
    getStationsByZipcode: thunk((actions) => {

    })
}

export default LocationsModel;
