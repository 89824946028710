import { ReactComponent as IconCheck } from "../../../assets/icon-check.svg";

interface PackageTypeProps {
    id: number,
    name: string,
    symbol: string,
    weight: string,
    description: string,
    selected: number
}

const PackageType = ({ id, symbol, weight, description, selected }: PackageTypeProps) => (
    <div className="flex border-b-diver border-b px-6 pb-4.5 mb-1.5 pt-4.5 bg-white cursor-pointer">
        <div className={"flex w-full"}>
            <div className="flex w-12 h-fit justify-center rounded bg-light-green mr-5">
                <span className="text-green text-2xl px-4 py-1">{symbol}</span>
            </div>
            <div className="mr-8">
                <p className="font-bold">{weight}</p>
                <p className="text-color-gray-text">{description}</p>
            </div>
        </div>
        <div className={"flex justify-center items-center"}>
            <div className={`flex justify-center items-center w-6 h-6 rounded-full border-1 ${
                id === selected ? "border-green bg-green" : "border-grey"}`}>
                {id === selected ? <IconCheck fill={"white"} /> : null}
            </div>
        </div>
    </div>
)

export {PackageType}
